import React from "react";
import moment from "moment";
import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import { UikButton } from "@uik";

import externalLinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import ArrowUpIcon from "../../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../../images/icons/svg/sorting-arrow-down.svg";
import shareLinkGrey from "../../../images/icons/svg/share-link-grey.svg";
import searchIcon from "../../../images/icons/svg/search-grey.svg";
import CopySVG from "../../../images/icons/svg/copy-icon.svg";
import FullScreen from "../../../images/icons/svg/full-screen.svg";
import { useTranslation } from "react-i18next";

import styles from "./ShareLinksTable.module.scss";

export default (props) => {
  const {
    shareLinkData,
    sortingColumnIndex,
    sortOrder,
    showActivityLog,
    noSearchResults,
  } = props;

  const { useState, useEffect, createRef } = React;

  const [elRefs, setElRefs] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (shareLinkData.length > 0) {
      let refs = [];
      shareLinkData.map((link, index) => {
        refs.push(createRef());
      });
      setElRefs(refs);
    }
  }, [shareLinkData]);

  const currentUserRole = security.getUserRole();

  let tableProperties = [
    "title",
    "link label",
    "last accessed",
    "downloads",
    "clicks",
    "total time viewed",
  ];

  if (currentUserRole === "admin") {
    tableProperties.splice(2, 0, "created by");
  }

  const renderDuration = (ms) => {
    const seconds =
      Math.round(moment.duration(ms, "milliseconds").asSeconds() * 100) / 100;
    if (seconds < 1 && seconds > 0) {
      return `${seconds} seconds`;
    } else if (seconds > 1) {
      return `${
        Math.round(moment.duration(ms, "milliseconds").asMinutes() * 100) / 100
      } minutes`;
    } else {
      return "-";
    }
  };

  const rows = {};

  shareLinkData.length > 0 &&
    shareLinkData.map((link) => {
      rows[link.id] = [];
      tableProperties.map((df, index) => {
        if (df === "title") {
          rows[link.id].push(link.document_name);
        } else if (df === "link label") {
          rows[link.id].push(link.label);
        } else if (df === "last accessed") {
          rows[link.id].push(
            link.last_accessed
              ? moment(link.last_accessed).format("MMMM D, YYYY [at] h:mma")
              : "-"
          );
        } else if (df === "downloads") {
          if (link.share_link_analytics.downloads) {
            rows[link.id].push(link.share_link_analytics.downloads.length);
          } else {
            rows[link.id].push(0);
          }
        } else if (df === "clicks") {
          if (link.share_link_analytics.views) {
            rows[link.id].push(link.share_link_analytics.views.length);
          } else {
            rows[link.id].push(0);
          }
        } else if (df === "total time viewed") {
          rows[link.id].push(renderDuration(link.total_time_viewed));
        } else if (df === "created by") {
          rows[link.id].push(link.created_by_user_name);
        }
      });
    });

  const onLinkHover = (linkIndex) => {
    elRefs[linkIndex].current.style.display = "flex";
    elRefs[linkIndex].current.style.visibility = "visible";
    elRefs[linkIndex].current.style.background = "#f7fbfc";
  };

  const onLinkLeave = (linkIndex) => {
    elRefs[linkIndex].current.style.display = "none";
  };

  const viewContent = (link) => {
    if (link.document_approved) {
      window.open(`/documents/${link.document_id}`);
    } else {
      window.open(`/drafts/${link.document_id}`);
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/share_links/view/${link.hash_url}`
    );

    notificationService.addNotification(
      "Link copied",
      `Your link “${link.label}” has been copied to clipboard!`,
      "success"
    );
  };

  const renderEmptyState = () => {
    if (noSearchResults) {
      return (
        <div className={styles.emptyStateContainer}>
          {
            <div
              className={
                currentUserRole === "admin"
                  ? styles.emptyMessageContainerAdmin
                  : styles.emptyMessageContainer
              }
            >
              <img className={styles.shareLinkGrey} src={searchIcon} />
              {t("share_link_analytics.no_results")}
            </div>
          }
        </div>
      );
    } else {
      return (
        <div className={styles.emptyStateContainer}>
          {
            <div
              className={
                currentUserRole === "admin"
                  ? styles.emptyMessageContainerAdmin
                  : styles.emptyMessageContainer
              }
            >
              <img className={styles.shareLinkGrey} src={shareLinkGrey} />
              {t("share_link_analytics.no_links_created")}
            </div>
          }
        </div>
      );
    }
  };

  const renderTableView = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.tableSubContainer}>
          <table className={styles.tableContainer}>
            <colgroup className={styles.colgroup}>
              {tableProperties.map((column, index) =>
                index === 0 ? (
                  <col key={index} span="1" className={styles.colLarge} />
                ) : (
                  <col key={index} span="1" className={styles.col} />
                )
              )}
            </colgroup>
            <thead className={styles.header}>
              <tr>
                {tableProperties.map((column, index) => {
                  return (
                    <th className={styles.unsortableColumnName} key={index}>
                      <div className={styles.container}>
                        <div
                          className={`${styles.text} ${
                            sortingColumnIndex === index && styles.textBold
                          }`}
                        >
                          {column}
                        </div>
                        <div className={styles.icon}>
                          <img
                            className={
                              sortingColumnIndex === index &&
                              sortOrder === "desc"
                                ? styles.show
                                : ""
                            }
                            src={ArrowDownIcon}
                          />
                          <img
                            className={`${
                              sortingColumnIndex === index
                                ? styles.arrowUpShow
                                : ""
                            } ${
                              sortingColumnIndex === index &&
                              sortOrder === "asc"
                                ? styles.show
                                : ""
                            }`}
                            src={ArrowUpIcon}
                          />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            {shareLinkData.length === 0 ? (
              renderEmptyState()
            ) : (
              <tbody className={styles.body}>
                {shareLinkData.map((link, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => onLinkHover(index)}
                    onMouseLeave={() => onLinkLeave(index)}
                    className={styles.tableRow}
                  >
                    {rows[link.id].map((cell, index) => {
                      return <td key={index}>{cell ? cell : "-"}</td>;
                    })}
                    <div
                      className={styles.hoverStateContainer}
                      ref={elRefs[index]}
                    >
                      <div className={styles.linksContainer}>
                        <UikButton
                          className={styles.exportButton}
                          onClick={() => viewContent(link)}
                        >
                          {t("share_link_analytics.view_content")}
                          <img
                            className={styles.externalLink}
                            src={externalLinkIcon}
                          />
                        </UikButton>
                        <UikButton
                          className={styles.exportButton}
                          onClick={(e) => copyLink(link)}
                        >
                          {t("share_link_analytics.copy_link")}
                          <img className={styles.externalLink} src={CopySVG} />
                        </UikButton>
                        <UikButton
                          className={styles.exportButton}
                          onClick={(e) => showActivityLog(link)}
                        >
                          {t("share_link_analytics.view_activity_log")}
                          <img
                            className={styles.externalLink}
                            src={FullScreen}
                          />
                        </UikButton>
                      </div>
                    </div>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  };

  return <div>{renderTableView()}</div>;
};
