import React, { useState, useEffect } from "react";

import history from "../../../history";
import httpClient from "../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import Dropzone from "react-dropzone";
import NewModal from "../../Common/Modal";
import UploadIcon from "../../../images/icons/svg/upload.svg";
import CloseIcon from "../../../images/icons/svg/close-grey.svg";
import textIcon from "../../../images/icons/svg/text-category-icon.svg";
import imageIcon from "../../../images/icons/svg/image-category-icon.svg";
import Button from "../../Common/Buttons/ButtonPrimary";
import styles from "./EditCategoryModal.module.scss";
import { StructuredContent } from "../StructuredContent";

export default (props) => {
  const [structuredContentCategory, setStructuredContentCategory] = useState(
    {}
  );
  const [nameError, setNameError] = useState("");
  const [labelError, setLabelError] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [labelPrefix, setLabelPrefix] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryLookup, setCategoryLookup] = useState("");
  const [editableFieldStatus, setEditableFieldStatus] = useState(true);

  useEffect(() => {
    if (props.selectedCategoryId > 0) {
      httpClient
        .get(`/structured_content_categories/${props.selectedCategoryId}.json`)
        .then((res) => {
          setStructuredContentCategory(res.data);
          setCategoryType(res.data.column_type);
          setLabelPrefix(res.data.label_prefix);
          setCategoryName(res.data.name);
          setCategoryLookup(res.data.lookup);
          setEditableFieldStatus(res.data.active);
        });
    }
  }, []);

  const handleCategoryNameChange = (e) => {
    const formattedLookup = e.target.value.toLowerCase().split(" ").join("_");
    setCategoryName(e.target.value);
    setCategoryLookup(formattedLookup);
    setNameError("");
  };

  const generateCategoryLabel = (e) => {
    let splitLabel = e.target.value.split(" ");
    let generatedLabel;

    if (splitLabel.length > 1) {
      generatedLabel = splitLabel
        .map((word, index) => {
          if (index <= 3) {
            return word[0];
          }
        })
        .join("")
        .toUpperCase();
    } else {
      let firstFourCharacters = e.target.value
        .split("")
        .map((char, index) => {
          if (index <= 3) {
            return char;
          }
        })
        .join("");

      generatedLabel = firstFourCharacters.toUpperCase();
    }

    setLabelPrefix(generatedLabel);
    setLabelError("");
  };

  const handleLabelChange = (e) => {
    setLabelError("");
    setLabelPrefix(e.target.value);
  };

  const toggleEditableFieldStatus = (editable) => {
    setEditableFieldStatus(editable);
  };

  const handleClose = () => {
    history.push(`/structured_content/category/${props.selectedCategoryId}`);
    props.setShowEditCategoryModal(false);
  };

  const handleSave = (e) => {
    let formData = {
      name: categoryName,
      label_prefix: labelPrefix,
      active: editableFieldStatus,
      column_type: categoryType,
      lookup: categoryLookup,
    };

    httpClient
      .put(
        `/structured_content_categories/${props.selectedCategoryId}.json`,
        formData
      )
      .then((response) => {
        notificationService.addNotification(
          "Structured Content Category Saved",
          "Your changes to this category has been saved",
          "success"
        );
        handleClose();
      })
      .catch((error) => {
        let prefixErrorMessage = "";

        let nameErrorMessage = "";

        if (error.response.data.label_prefix) {
          if (error.response.data.label_prefix[0] === "can't be blank") {
            notificationService.addNotification(
              "Please enter a Label Prefix",
              "Please enter a Label Prefix",
              "warning"
            );
            prefixErrorMessage = "Please enter a Label Prefix";
          } else {
            notificationService.addNotification(
              "This Label Prefix already exists",
              "This Label Prefix already exists",
              "warning"
            );
            prefixErrorMessage = "This Label Prefix already exists";
          }
        }

        if (error.response.data.name) {
          if (error.response.data.name[0] === "can't be blank") {
            notificationService.addNotification(
              "Please enter a Category Name",
              "Please enter a Category Name",
              "warning"
            );
            nameErrorMessage = "Please enter a Category Name";
          } else {
            notificationService.addNotification(
              "This Category Name already exists",
              "This Category Name already exists",
              "warning"
            );
            nameErrorMessage = "This Category Name already exists";
          }
        }
        setNameError(nameErrorMessage);
        setLabelError(prefixErrorMessage);
      });
  };

  const handleDelete = (e) => {
    httpClient
      .delete(`/structured_content_categories/${props.selectedCategoryId}.json`)
      .then((response) => {
        notificationService.addNotification(
          "Structured Content Category Deleted",
          "Structured Content Category Deleted",
          "success"
        );
        props.setShowEditCategoryModal(false);
        history.push(`/structured_content/`);
        httpClient
          .get(`/structured_content_categories.json`)
          .then((response) => {
            props.setCategories(response.data);
          });
      })
      .catch((error) => {});
  };

  const handleCategoryType = (type) => {
    if (!structuredContentCategory.has_structured_content_items) {
      setCategoryType(type);
    }
  };

  const renderEditForm = () => {
    return (
      <div>
        <div>
          <div>
            <UikFormInputGroup className={styles.formContainer}>
              <div className={styles.newCategoryFormCol}>
                <UikInput
                  disabled={
                    structuredContentCategory.has_structured_content_items
                  }
                  onChange={(e) => {
                    handleCategoryNameChange(e);
                  }}
                  className={
                    nameError
                      ? styles.inputFieldContainerError
                      : styles.inputFieldContainer
                  }
                  label="CATEGORY NAME"
                  placeholder=""
                  onBlur={(e) => {
                    generateCategoryLabel(e);
                  }}
                  value={categoryName}
                />
                {nameError && (
                  <p className={styles.errorMessage}>{nameError}</p>
                )}
                <UikInput
                  disabled={
                    structuredContentCategory.has_structured_content_items
                  }
                  onChange={(e) => {
                    handleLabelChange(e);
                  }}
                  className={
                    labelError
                      ? styles.inputFieldContainerError
                      : styles.inputFieldContainer
                  }
                  label="LABEL PREFIX"
                  placeholder=""
                  value={labelPrefix}
                />
                {labelError && (
                  <p className={styles.errorMessage}>{labelError}</p>
                )}
                <div className={styles.customInputContainer}>
                  {/* <div className={styles.customInputLabel}>Editable Fields</div>
                  {structuredContentCategory && (
                    <div>
                      <UikToggle
                        checked={editableFieldStatus}
                        onChange={(e) =>
                          toggleEditableFieldStatus(e.target.checked)
                        }
                      />
                      <span className={styles.editableFieldsActive}>
                        Active
                      </span>
                    </div>
                  )} */}
                  <div className={styles.statusDescription}>
                    The placeholder for this category is:
                    <br />
                    {`{{structured:${categoryLookup}}}`}
                    <br />
                    <br />
                    If you would like to include more than one instance of it,
                    simply append a field name to it like so:
                    <br />
                    {`{{structured:${categoryLookup}:editable_field_name}}`}
                    <div
                      className={styles.kbLink}
                      onClick={() => {
                        window.open(
                          `https://help.papercurve.com/knowledge-base/structured-content-authoring`
                        );
                      }}
                    >
                      Learn more
                    </div>
                  </div>
                </div>
                <div className={styles.customInputContainer}>
                  <div className={styles.customInputLabel}>Category Type</div>
                  <div className={styles.categoryTypeSelection}>
                    <div
                      className={`${styles.categoryType} ${
                        categoryType === "text" ? styles.selected : ""
                      } ${
                        structuredContentCategory.has_structured_content_items
                          ? styles.disabled
                          : ""
                      }`}
                      onClick={() => handleCategoryType("text")}
                    >
                      <img className={styles.categoryIcon} src={textIcon} />
                      Text
                    </div>
                    <div
                      className={`${styles.categoryType} ${
                        categoryType === "image" ? styles.selected : ""
                      } ${
                        structuredContentCategory.has_structured_content_items
                          ? styles.disabled
                          : ""
                      }`}
                      onClick={() => handleCategoryType("image")}
                    >
                      <img className={styles.categoryIcon} src={imageIcon} />
                      Image
                    </div>
                  </div>
                </div>
              </div>
            </UikFormInputGroup>
          </div>
        </div>
      </div>
    );
  };

  return (
    <NewModal
      // hideModal={props.showNewItemModal}
      visible={true}
      type="regular"
    >
      <div className="heading">Edit Category</div>
      <UikDivider />
      <div className="body">
        <div>{renderEditForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <div className={styles.buttonContainer}>
          <div className={styles.leftContainer}>
            {!structuredContentCategory.has_structured_content_items && (
              <UikButton onClick={(e) => handleDelete(e)} error>
                Delete
              </UikButton>
            )}
          </div>
          <div className={styles.rightContainer}>
            <UikButton onClick={() => handleClose()}>Cancel</UikButton>
            <UikButton onClick={(e) => handleSave(e)} primary>
              Save
            </UikButton>
          </div>
        </div>
      </div>
    </NewModal>
  );
};
