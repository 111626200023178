import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";

import styles from "./GroupedDocumentReferencesPanel.module.scss";
import axios from "../../lib/axios-client";
import httpClient from "../../lib/HttpClient";
import notificationService from "../../services/Notifications";
import referencesService from "../../services/References";
import fetchReferenceDocuments from "../../actions/document/fetchReferenceDocuments";
import fetchDocumentReferences from "../../actions/document/fetchDocumentReferences";
import NewModal from "../Common/Modal";

import referenceIcon from "../../images/icons/16px/link.svg";
import linkIcon from "../../images/icons/svg/link-icon.svg";
import Warning from "../../images/icons/png/warning.svg";
import moreIcon from "../../images/icons/more.svg";
import Close from "../../images/icons/svg/close.svg";
import StepOneFilled from "../../images/icons/svg/step-1-filled.svg";
import StepTwoFilled from "../../images/icons/svg/step-2-filled.svg";
import StepTwoOutline from "../../images/icons/svg/step-2-outline.svg";
import lineGreen from "../../images/icons/svg/line-green.svg";
import lineWhite from "../../images/icons/svg/line-white.svg";
import Button from "../Common/Buttons/ButtonPrimary";
import noUploadIcon from "../../images/icons/png/noUpload.png";
import backArrow from "../../images/icons/svg/back-arrow.svg";
import Pindrop from "../../images/icons/svg/pindrop.svg";
import Trash from "../../images/icons/svg/trash-teal.svg";
import PaigeReferences from "./PaigeReferences";
import CloseSVG from "../../images/icons/svg/close.svg";

import security from "../../services/Security";

import dimpleSVG from "../../images/icons/svg/toolbox-dimple-blue.svg";
import dimpleSVGBlue from "../../images/icons/svg/toolbox-dimple-blue.svg";
import leftarrowSVG from "../../images/icons/svg/left-arrow.svg";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import history from "../../history";

/* Variables */

class ReferencePanelBuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referenceFiles: [],
      groupedDocumentReferences: null,
      searchValue: null,
      filteredReferences: [],
      referenceAnnotations: null,
      choosingAnnotation: false,
      selectedReferenceId: null,
      showPaigePanel: this.props.library.paige_enabled,
      showDocumentReferenceDeleteModal: false,
      docRefToDelete: null,
      showDeleteAllLinkedDocumentRefsModal: false,
      showRedefineModal: false,
      redefinedAnnotationXfdfHighlightId: null,
      documentReferencesToRedefine: [],
      documentReferencesByPage: {},
      oldAnnotationPageNumber: null,
      oldAnnotation: null,
    };
  }

  componentWillMount = () => {
    if (this.props.legacyDocument) {
      this.legacyRetrieveReference();
    } else {
      this.currentRetrieveReference();
    }

    this.props.documentReferences && this.setDocumentReferencesByPage();
  };

  legacyRetrieveReference = () => {
    httpClient
      .get(`/document/references/${this.props.referenceBlockId}.json`)
      .then((response) => {
        this.setState({ groupedDocumentReferences: response.data });
      });
  };

  currentRetrieveReference = () => {
    httpClient
      .get(`/document/references/${this.props.referenceXfdfHighlightId}.json`)
      .then((response) => {
        this.setState({ groupedDocumentReferences: response.data });
        if (response.data.length > 0) {
          this.props.setDocumentReferenceId(response.data[0].id);
        }
      });
  };

  getPageNumber = (ref) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(
      ref.xfdf_highlight_id
    );

    if (annotation) {
      return annotation.getPageNumber();
    }
  };

  setDocumentReferencesByPage = () => {
    let documentReferencesObj = {};

    this.props.documentReferences.forEach((dr) => {
      const pageNumber = this.getPageNumber(dr);

      if (!documentReferencesObj[pageNumber]) {
        documentReferencesObj[pageNumber] = [];
      }
      documentReferencesObj[pageNumber].push(dr);
    });

    this.setState({ documentReferencesByPage: documentReferencesObj });
  };

  highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? styles.highlight
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  retrieveDocumentReferences = (id) => {
    httpClient.get(`/documents/${id}/document_references.json`).then((res) => {
      this.setState({
        referenceAnnotations: res.data,
        filteredReferenceAnnotations: res.data,
        choosingAnnotation: true,
        selectedReferenceId: id,
        searchValue: "",
      });
    });
  };

  renderRedefinedDocumentReferences = () => {
    return (
      <div className={styles.sidePanelOptionsDocRefs}>
        {this.state.documentReferencesToRedefine.map((ref, idx) => {
          return this.renderRedefinedDocumentReference(ref, idx);
        })}
      </div>
    );
  };

  renderReferencesList = (filteredReferenceAnnotations) => {
    let referencesHT = {};
    if (filteredReferenceAnnotations) {
      filteredReferenceAnnotations.forEach((ref) => {
        if (!referencesHT[ref.page.page_number]) {
          referencesHT[ref.page.page_number] = [];
        }
        referencesHT[ref.page.page_number].push(ref);
      });
      return (
        <div>
          {Object.entries(referencesHT).map(([key, refArr], index) => (
            <div key={index}>
              {!this.state.searchValue && (
                <div>
                  <div className={styles.pageNumber}>Page {key}</div>
                  <div className={styles.dividerContainer} />
                </div>
              )}
              <div>
                {refArr.map((ref) => (
                  <div className={styles.referenceCard}>
                    <div className={styles.attachmentReferenceAnnotIdContainer}>
                      <div
                        className={
                          ref.document.reference_document_label && ref.label
                            ? styles.attachmentReferenceId
                            : styles.emptyAttachmentReferenceId
                        }
                      >
                        {ref.document.reference_document_label && ref.label && (
                          <div className={styles.referenceDocumentLabel}>
                            {this.state.searchValue
                              ? this.highlightSearchTerm(
                                  ref.document.reference_document_label,
                                  this.state.searchValue
                                )
                              : ref.document.reference_document_label}
                          </div>
                        )}
                        {ref.document.reference_document_label && ref.label ? (
                          <div className={styles.referenceLabel}>
                            |{" "}
                            {this.state.searchValue
                              ? this.highlightSearchTerm(
                                  ref.label,
                                  this.state.searchValue
                                )
                              : ref.label}
                          </div>
                        ) : (
                          "No Label"
                        )}
                        <div className={styles.fullLabelsContainer}>
                          <div className={styles.fullLabels}>
                            {`${
                              ref.document.reference_document_label &&
                              ref.label &&
                              ref.document.reference_document_label
                            } ${
                              ref.document.reference_document_label && ref.label
                                ? `| ${ref.label}`
                                : "No Label"
                            }`}
                          </div>
                          <div className={styles.bottomArrow} />
                        </div>
                      </div>
                    </div>
                    {ref.caption === "Pindrop Reference" && (
                      <div className={styles.pindropCaptionContainer}>
                        <img className={styles.pinIcon} src={Pindrop} />
                        <div
                          className={`${styles.pinReferenceCaption} ${
                            this.state.searchValue
                              ? styles.linkingReferenceCaptionSearch
                              : ""
                          }`}
                        >
                          {this.state.searchValue
                            ? this.highlightSearchTerm(
                                ref.caption,
                                this.state.searchValue
                              )
                            : ref.caption}
                        </div>
                      </div>
                    )}
                    {ref.caption !== "Pindrop Reference" && (
                      <div className={styles.captionContainer}>
                        <div
                          className={`${styles.linkingReferenceCaption} ${
                            this.state.searchValue
                              ? styles.linkingReferenceCaptionSearch
                              : ""
                          }`}
                        >
                          {this.state.searchValue
                            ? this.highlightSearchTerm(
                                ref.caption,
                                this.state.searchValue
                              )
                            : ref.caption}
                        </div>
                      </div>
                    )}
                    <div className={styles.countContainer}>
                      <div
                        onClick={() => this.props.linkExistingAnnotation(ref)}
                        className={styles.linkAnnotation}
                      >
                        Link to Citation
                      </div>
                      <span className={styles.annotationCountLinkScreen}>
                        {ref.document_references.length}
                      </span>
                      <div className={styles.descriptionBoxContainer}>
                        <div className={styles.descriptionBox}>
                          <img
                            className={styles.descriptionBoxImage}
                            src={dimpleSVGBlue}
                          />
                          <div className={styles.descriptionText}>
                            {"Number of times this annotation is linked"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.dividerContainer} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.referenceXfdfHighlightId === null &&
      prevProps.referenceXfdfHighlightId !== null
    ) {
      this.setState({ groupedDocumentReferences: null });

      if (this.props.linkingReference) {
        const webViewerEl = document.getElementById("webViewer");
        const annotManager =
          window.WebViewer.getInstance(
            webViewerEl
          ).docViewer.getAnnotationManager();
        const annot = annotManager.getAnnotationById(
          prevProps.referenceXfdfHighlightId
        );

        annotManager.deleteAnnotation(annot);
      }
    }
    if (
      this.props.referenceXfdfHighlightId !==
        prevProps.referenceXfdfHighlightId &&
      this.props.referenceXfdfHighlightId !== null
    ) {
      httpClient
        .get(`/document/references/${this.props.referenceXfdfHighlightId}.json`)
        .then((response) => {
          this.setState({ groupedDocumentReferences: response.data });
          if (response.data.length > 0) {
            this.props.setDocumentReferenceId(response.data[0].id);
          }
        });
    }
  }

  onReferenceDocumentsDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();

    this.setState({
      referenceFiles: this.state.referenceFiles.concat(acceptedFiles),
    });

    acceptedFiles.map((referenceFile, index) => {
      const pctIndex = this.state.referenceFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (progressEvent) => {
          const referenceFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (referenceFilePercent >= 100) {
            this.setState({ ["referenceFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["referenceFilePercentages" + pctIndex]: referenceFilePercent,
            });
          }
        },
      };

      this.setState({ ["referenceFilePercentages" + pctIndex]: 0 });

      const referenceFormData = new FormData();
      referenceFormData.append("document[title]", referenceFile.name);
      referenceFormData.append("document[document_file]", referenceFile);
      referenceFormData.append(
        "document[reference_document_id]",
        this.props.docId
      );
      referenceFormData.append("document[reference_document]", true);

      axios
        .post("/documents.json", referenceFormData, postConfig)
        .then((response) => {
          let docRefs = this.state.references;
          docRefs.push(response.data);
          //let referenceDocuments = this.state.referenceDocuments;
          //referenceDocuments.push(response.data);
          this.setState({
            referenceDocuments: docRefs,
            filteredReferences: docRefs,
            referenceFiles: [],
          });
          console.log("saved!");
        })
        .catch((error) => {
          security.checkAuth(error);
          console.log("error", error);
          this.setState({
            ["referenceFileError" + pctIndex]: "error",
            ["referenceFilePercentages" + pctIndex]: 100,
          });
        });
    });
  };

  renderReferenceDocumentsUploader = () => {
    return (
      <div className={styles.referenceContentUploaderBox}>
        <Dropzone onDrop={this.onReferenceDocumentsDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop reference documents here
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop files here to upload
                    </span>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderReferenceDocument = (ref, idx) => {
    return (
      <div
        onClick={() => {
          this.retrieveDocumentReferences(ref.id);
        }}
        key={ref.id}
        className={styles.referenceContentUploading}
      >
        <div className={styles.attachmentReferenceIdContainer}>
          <span
            className={
              ref.reference_document_label
                ? styles.attachmentReferenceId
                : styles.emptyAttachmentReferenceId
            }
          >
            {ref.reference_document_label
              ? this.state.searchValue
                ? this.highlightSearchTerm(
                    ref.reference_document_label,
                    this.state.searchValue
                  )
                : ref.reference_document_label
              : "No Label"}
          </span>
          {security.getUserRole() !== "viewer" &&
            this.props.viewingCurrentVersion && (
              <UikDropdown
                DisplayComponent={this.actionsDropDown}
                position="bottomRight"
                onClick={(e) => e.stopPropagation()}
                className={styles.referenceDocumentMenu}
              >
                <UikDropdownItem
                  onClick={() => this.deleteDocumentReference(ref)}
                >
                  Delete
                </UikDropdownItem>
              </UikDropdown>
            )}
        </div>
        <div>
          <div className={styles.attachmentDocumentTitle}>
            {this.state.searchValue
              ? this.highlightSearchTerm(ref.title, this.state.searchValue)
              : ref.title}
          </div>
          <div className={styles.attachmentDocumentDescription}>
            {this.state.searchValue
              ? this.highlightSearchTerm(
                  ref.description,
                  this.state.searchValue
                )
              : ref.description}
          </div>
        </div>
        <div className={styles.attachmentCardFooter}>
          <div className={styles.annotationCountContainer}>
            <span className={styles.annotationCount}>
              {ref.annotation_count}
            </span>
            <div className={styles.descriptionBox}>
              <img className={styles.descriptionBoxImage} src={dimpleSVG} />
              <div className={styles.descriptionText}>
                {"Number of annotations in this reference document"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderRedefinedDocumentReference = (ref, idx) => {
    return (
      <div
        key={ref.reference.document_id}
        className={styles.documentReferenceCard}
      >
        <div className={styles.documentReferenceCardSubContainer}>
          <div className={styles.topContainer}>
            <div
              className={
                ref.reference.document.reference_document_label &&
                ref.reference.label
                  ? styles.attachmentReferenceId
                  : styles.emptyAttachmentReferenceId
              }
            >
              {ref.reference.document.reference_document_label &&
                ref.reference.label && (
                  <div className={styles.referenceDocumentLabel}>
                    {ref.reference.document.reference_document_label}
                  </div>
                )}
              {ref.reference.document.reference_document_label &&
              ref.reference.label ? (
                <div className={styles.referenceLabel}>
                  | {ref.reference.label}
                </div>
              ) : (
                "No Label"
              )}
              <div className={styles.fullLabelsContainer}>
                <div className={styles.fullLabels}>
                  {`${
                    ref.reference.document.reference_document_label &&
                    ref.reference.label &&
                    ref.reference.document.reference_document_label
                  } ${
                    ref.reference.document.reference_document_label &&
                    ref.reference.label
                      ? `| ${ref.reference.label}`
                      : "No Label"
                  }`}
                </div>
                <div className={styles.bottomArrow} />
              </div>
            </div>
          </div>
          {ref.reference.caption === "Pindrop Reference" && (
            <div className={styles.pindropCaptionContainer}>
              <img className={styles.pinIcon} src={Pindrop} />
              <div className={styles.pinReferenceCaption}>
                {ref.reference.caption}
              </div>
            </div>
          )}
          {ref.reference.caption !== "Pindrop Reference" && (
            <div className={styles.captionContainer}>
              <div className={styles.linkingReferenceCaption}>
                {ref.reference.caption}
              </div>
            </div>
          )}
          <div className="clear"></div>
        </div>
      </div>
    );
  };

  renderDocumentReference = (ref, idx) => {
    return (
      <div
        key={ref.reference.document_id}
        className={styles.documentReferenceCard}
      >
        <div className={styles.documentReferenceCardSubContainer}>
          <div className={styles.topContainer}>
            <div
              className={
                ref.reference.document.reference_document_label &&
                ref.reference.label
                  ? styles.attachmentReferenceId
                  : styles.emptyAttachmentReferenceId
              }
            >
              {ref.reference.document.reference_document_label &&
                ref.reference.label && (
                  <div className={styles.referenceDocumentLabel}>
                    {ref.reference.document.reference_document_label}
                  </div>
                )}
              {ref.reference.document.reference_document_label &&
              ref.reference.label ? (
                <div className={styles.referenceLabel}>
                  | {ref.reference.label}
                </div>
              ) : (
                "No Label"
              )}
              <div className={styles.fullLabelsContainer}>
                <div className={styles.fullLabels}>
                  {`${
                    ref.reference.document.reference_document_label &&
                    ref.reference.label &&
                    ref.reference.document.reference_document_label
                  } ${
                    ref.reference.document.reference_document_label &&
                    ref.reference.label
                      ? `| ${ref.reference.label}`
                      : "No Label"
                  }`}
                </div>
                <div className={styles.bottomArrow} />
              </div>
            </div>
            {security.getUserRole() !== "viewer" && !this.props.readOnly && (
              <div
                className={styles.deleteContainer}
                onClick={() => {
                  this.showDeleteModal(ref.id);
                }}
              >
                <img className={styles.trashContainer} src={Trash} />
                Delete
              </div>
            )}
          </div>
          {ref.reference.caption === "Pindrop Reference" && (
            <div className={styles.pindropCaptionContainer}>
              <img className={styles.pinIcon} src={Pindrop} />
              <div className={styles.pinReferenceCaption}>
                {ref.reference.caption}
              </div>
            </div>
          )}
          {ref.reference.caption !== "Pindrop Reference" && (
            <div className={styles.captionContainer}>
              <div className={styles.linkingReferenceCaption}>
                {ref.reference.caption}
              </div>
            </div>
          )}
          <div className="clear"></div>
          <Link
            to={{
              pathname: `/references/${ref.reference.document_id}/pid/${ref.reference.xfdf_highlight_id}`,
              state: {
                docId: ref.document_id,
                readOnly: this.props.readOnly,
                versionId: this.props.versionId,
                versionNumber: this.props.versionNumber,
                originalDocumentId: this.props.originalDocumentId,
                isApproved: this.props.isApproved,
              },
            }}
            className={styles.attachmentDocumentLink}
          >
            View in Reference Document
          </Link>
        </div>
      </div>
    );
  };

  goBack = () => {
    this.setState({
      choosingAnnotation: false,
      referenceAnnotations: null,
      searchValue: "",
    });
  };

  deleteAllLinkedReferences = (ref) => {
    this.setState({ showDeleteAllLinkedDocumentRefsModal: false });
    const documentReferencesIds = this.state.groupedDocumentReferences.map(
      (dr) => {
        return dr.id;
      }
    );
    const params = {
      linked_refs_to_delete: documentReferencesIds,
    };
    httpClient
      .post(`/document_references/destroy_all_linked.json`, params)
      .then((response) => {
        this.props.fetchDocumentReferences(this.props.docId);
        referencesService.decrementReferences();
        if (this.props.referenceXfdfHighlightId) {
          const webViewerEl = document.getElementById("webViewer");
          const annotManager =
            window.WebViewer.getInstance(
              webViewerEl
            ).docViewer.getAnnotationManager();
          const annot = annotManager.getAnnotationById(
            this.props.referenceXfdfHighlightId
          );

          annotManager.deleteAnnotation(annot);
          this.props.close();
        }

        notificationService.addNotification(
          "Linked references removed.",
          "Linked references deleted.",
          "success"
        );
      })
      .catch((error) => {
        security.checkAuth(error);
        this.props.close();
        notificationService.addNotification(
          "Reference deletion not allowed.",
          "You do not have permission to delete this reference.",
          "danger"
        );
      });
  };

  onDeleteReference = (referenceToDelete) => {
    const { referenceXfdfHighlightId } = this.props;
    this.setState({
      showDocumentReferenceDeleteModal: false,
      docRefToDelete: null,
    });
    httpClient
      .delete(
        `/document_references/${
          referenceToDelete
            ? referenceToDelete
            : this.state.groupedDocumentReferences[0].id
        }.json`
      )
      .then((response) => {
        this.props.fetchDocumentReferences(this.props.docId);
        httpClient
          .get(
            `/document/references/${this.props.referenceXfdfHighlightId}.json`
          )
          .then((response) => {
            this.setState({ groupedDocumentReferences: response.data });
            if (response.data.length === 0) {
              referencesService.decrementReferences();
              if (referenceXfdfHighlightId) {
                const webViewerEl = document.getElementById("webViewer");
                const annotManager =
                  window.WebViewer.getInstance(
                    webViewerEl
                  ).docViewer.getAnnotationManager();
                const annot = annotManager.getAnnotationById(
                  referenceXfdfHighlightId
                );

                annotManager.deleteAnnotation(annot);
              }
              this.props.close();
            }
          });

        notificationService.addNotification(
          "Reference removed.",
          "Reference has been deleted.",
          "success"
        );
        this.props.fetchReferenceDocuments(this.props.docId);
      })
      .catch((error) => {
        security.checkAuth(error);
        this.props.close();
        notificationService.addNotification(
          "Reference deletion not allowed.",
          "You do not have permission to delete this reference.",
          "danger"
        );
      });
  };

  disablePDFtronTools = () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    instance.disableElements([
      "pinToolsGroupButton",
      "signatureToolButton",
      "shapesToolButton",
    ]);
  };

  enablePDFtronTools = () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    instance.enableElements([
      "pinToolsGroupButton",
      "signatureToolButton",
      "shapesToolButton",
    ]);
  };

  undoRedefiningDocumentReference = async () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    instance.setToolMode("AnnotationEdit");

    if (this.state.oldAnnotation) {
      this.props.hideAllAnnots();

      const annotation = this.state.oldAnnotation;

      await instance.annotManager.redrawAnnotation(annotation);

      const newAnnotation = instance.annotManager.getAnnotationById(
        this.props.redefinedXFDFId
      );

      if (newAnnotation) {
        await instance.annotManager.hideAnnotation(newAnnotation);
      }

      let newStyles;

      if (annotation.Subject === "Stamp") {
        newStyles = {
          Opacity: 1,
        };
      } else {
        newStyles = {
          StrokeColor: new instance.Annotations.Color(122, 106, 141),
          FillColor: new instance.Annotations.Color(122, 106, 141),
        };
      }
      await instance.annotManager.setAnnotationStyles(annotation, newStyles);
      this.props.setUpdatedXFDF(null, null);
      this.enablePDFtronTools();
      this.props.close();

      await this.props.fetchDocumentReferences(this.props.docId).then(() => {
        this.props.hideAllAnnots();
      });

      this.setState({
        redefinedAnnotationXfdfHighlightId: null,
        inRedefineMode: false,
        documentReferencesToRedefine: [],
        oldAnnotationPageNumber: null,
        oldAnnotation: null,
      });
      this.props.setCurrentAnnotationText(null);
      this.props.setRedefineMode(false);
      this.props.stopEditingDocumentReferences();
    } else {
      const newAnnotation = instance.annotManager.getAnnotationById(
        this.props.redefinedXFDFId
      );

      if (newAnnotation) {
        await instance.annotManager.hideAnnotation(newAnnotation);
      }
      this.props.setUpdatedXFDF(null, null);
      this.enablePDFtronTools();
      this.props.close();
      this.setState({
        redefinedAnnotationXfdfHighlightId: null,
        inRedefineMode: false,
        documentReferencesToRedefine: [],
        oldAnnotationPageNumber: null,
        oldAnnotation: null,
      });
      this.props.setCurrentAnnotationText(null);
      this.props.setRedefineMode(false);
      this.props.stopEditingDocumentReferences();
    }
  };

  stopRedefiningDocumentReference = () => {
    this.props.setRedefineMode(false);
    this.props.stopEditingDocumentReferences();
    this.props.setUpdatedXFDF(null, null);

    this.setState({
      showRedefineModal: false,
      redefinedAnnotationXfdfHighlightId: null,
      inRedefineMode: false,
      documentReferencesToRedefine: [],
      oldAnnotationPageNumber: null,
      oldAnnotation: null,
    });
  };

  redefineOutOfBoundsDocumentReference = (docRef) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    if (docRef.caption === "") {
      instance.setToolMode("PinReferenceTool");
    } else {
      instance.setToolMode("AnnotationEdit");
    }

    const pageNumber = 1;

    this.setState({
      showRedefineModal: false,
      redefinedAnnotationXfdfHighlightId: docRef.xfdf_highlight_id,
      oldAnnotationPageNumber: pageNumber,
      oldAnnotation: null,
    });

    this.props.setRedefineMode(true);
  };

  startRedefiningDocumentReference = () => {
    this.props.startEditingDocumentReferences();
    this.disablePDFtronTools();

    this.setState({
      documentReferencesToRedefine: this.state.groupedDocumentReferences,
    });

    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    const annotation = instance.annotManager.getAnnotationById(
      this.state.groupedDocumentReferences[0].xfdf_highlight_id
    );

    if (annotation) {
      if (annotation.Subject === "Stamp") {
        instance.setToolMode("PinReferenceTool");
      } else {
        instance.setToolMode("AnnotationEdit");
      }

      const pageNumber = annotation.getPageNumber();

      this.setState({
        showRedefineModal: false,
        redefinedAnnotationXfdfHighlightId:
          this.state.groupedDocumentReferences[0].xfdf_highlight_id,
        oldAnnotationPageNumber: pageNumber,
        oldAnnotation: annotation,
      });

      // instance.annotManager.deleteAnnotation(annotation);

      if (annotation.Subject === "Stamp") {
        const newStyles = {
          Opacity: 0.2,
        };
        instance.annotManager.setAnnotationStyles(annotation, newStyles);
        instance.setToolMode("PinReferenceTool");
      } else {
        const newStyles = {
          StrokeColor: new instance.Annotations.Color(128, 128, 128),
          FillColor: new instance.Annotations.Color(128, 128, 128),
        };
        instance.annotManager.setAnnotationStyles(annotation, newStyles);
        instance.setToolMode("AnnotationEdit");
      }

      this.setState({
        showRedefineModal: false,
        redefinedAnnotationXfdfHighlightId:
          this.state.groupedDocumentReferences[0].xfdf_highlight_id,
      });

      this.props.setRedefineMode(true);
    } else {
      this.redefineOutOfBoundsDocumentReference(
        this.state.groupedDocumentReferences[0]
      );
    }
  };

  replaceXFDFHighlightId = (xfdfHighlightString, id, newId) => {
    const updatedXFDF = xfdfHighlightString.replace(id, newId);
    return updatedXFDF;
  };

  updateRedefinedDocumentReference = async () => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);

    const newAnnotationId = this.props.redefinedXFDFId;
    const xfdf = this.props.redefinedXFDF;

    const updatedXFDF = this.replaceXFDFHighlightId(
      xfdf,
      newAnnotationId,
      this.state.redefinedAnnotationXfdfHighlightId
    );

    const url = `redefine_document_references`;

    let documentReferenceToUpdate = {
      document_references: [this.state.redefinedAnnotationXfdfHighlightId],
      updated_xfdf: updatedXFDF,
      document_id: this.props.docId,
      caption: this.props.currentAnnotationText,
    };

    const redefinedAnnotXFDFId = this.state.redefinedAnnotationXfdfHighlightId;
    this.props.hideAllAnnots();
    httpClient
      .put(url, {
        document_references_to_update: documentReferenceToUpdate,
      })
      .then(() => {
        this.enablePDFtronTools();

        this.props.fetchDocumentReferences(this.props.docId).then(() => {
          const redefined = this.props.documentReferences.find(
            (dr) => dr.xfdf_highlight_id === redefinedAnnotXFDFId
          );

          instance.annotManager.importAnnotCommand(
            redefined.xfdf_highlight_string
          );
          this.props.hideAllAnnots();
        });

        this.stopRedefiningDocumentReference();
        this.props.setCurrentAnnotationText(null);
        this.props.close();
        notificationService.addNotification(
          "References have been redefined.",
          "References have been redefined.",
          "success"
        );
      });
  };

  deleteDocumentReference = (ref) => {
    let documentReferenceDocument = ref.reference_document_main_documents.find(
      (record) => record.document_id === this.props.docId
    );
    httpClient
      .delete(`/document_reference_documents/${documentReferenceDocument.id}`)
      .then((res) => {
        this.props
          .fetchReferenceDocuments(this.props.docId)
          .then((response) => {
            notificationService.addNotification(
              "Reference document deleted succesfully",
              "Reference document has been deleted",
              "success"
            );
          });
      })
      .catch(() => {
        notificationService.addNotification(
          "Reference document deletion not allowed.",
          "Reference document linked to a highlight cannot be deleted",
          "warning"
        );
      });
  };

  renderReferenceDocumentsUploaderProgress = (rf, idx) => {
    const rfProgressPercentage = this.state["referenceFilePercentages" + idx];
    const rfError = this.state["referenceFileError" + idx];
    return (
      <div className={styles.referenceContentUploading}>
        <div>
          {!rfError && (
            <span className={styles.uploaderIcon}>
              <img src={referenceIcon} />
            </span>
          )}
          <div className={styles.uploadingFilename}>{rf.name}</div>
          {rfError && (
            <div className={styles.errorBox}>
              <img src={Warning} className={styles.warning} />
              <span className={styles.errorMessage}>
                This type of file is not supported
              </span>
            </div>
          )}
          {!rfError && rfProgressPercentage == 100 && (
            <span className={styles.uploaderIconCheck}>
              <Uikon>check</Uikon>
            </span>
          )}
          {rfProgressPercentage < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={rfProgressPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  onSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  filteredResults = () => {
    if (!this.state.referenceAnnotations) {
      let referenceDocuments = this.props.referenceDocuments;
      if (this.state.searchValue) {
        const lcSearchValue = this.state.searchValue.toLowerCase();
        referenceDocuments = this.props.referenceDocuments.filter(
          (reference) =>
            reference.title.toLowerCase().includes(lcSearchValue) ||
            (reference.description &&
              reference.description.toLowerCase().includes(lcSearchValue)) ||
            (reference.reference_document_label &&
              reference.reference_document_label
                .toLowerCase()
                .includes(lcSearchValue))
        );
      }
      return referenceDocuments;
    } else {
      let referenceAnnotations = this.state.referenceAnnotations;
      if (this.state.searchValue) {
        const lcSearchValue = this.state.searchValue.toLowerCase();
        referenceAnnotations = this.state.referenceAnnotations.filter(
          (referenceAnnotation) =>
            (referenceAnnotation.document.reference_document_label &&
              referenceAnnotation.document.reference_document_label
                .toLowerCase()
                .includes(lcSearchValue)) ||
            (referenceAnnotation.label &&
              referenceAnnotation.label
                .toLowerCase()
                .includes(lcSearchValue)) ||
            (referenceAnnotation.caption &&
              referenceAnnotation.caption.toLowerCase().includes(lcSearchValue))
        );
      }
      return referenceAnnotations;
    }
  };

  createNewAnnotation = () => {
    history.push({
      pathname: `/references/${this.state.selectedReferenceId}`,
      state: {
        linkingReference: true,
        parentDocumentReferenceXFDF:
          this.state.groupedDocumentReferences.length > 0
            ? this.state.groupedDocumentReferences[0].xfdf_highlight_string
            : null,
      },
    });
  };

  actionsDropDown = ({ onClick }) => {
    return (
      <span onClick={onClick}>
        <img className={styles.moreIcon} src={moreIcon} />
      </span>
    );
  };

  onCloseLinkingReference = () => {
    if (
      this.state.groupedDocumentReferences &&
      this.state.groupedDocumentReferences.length > 0
    ) {
      this.props.close();
    } else {
      this.props.close(true);
    }
  };

  linkReferenceAnnotation = () => {
    if (this.state.showPaigePanel) {
      return (
        <PaigeReferences
          library={this.props.library}
          onClose={this.onCloseLinkingReference}
          linkExistingAnnotation={this.props.linkExistingAnnotation}
          linkManually={() => this.setState({ showPaigePanel: false })}
          referenceXfdfHighlightId={this.props.referenceXfdfHighlightId}
        />
      );
    } else {
      const filteredResults = this.filteredResults();
      return (
        <div className={styles.linkingReferenceContainer}>
          <div id="referencePanel" className={styles.linkingReference}>
            <div className={styles.newReferenceSidePanelHeader}>
              <span
                className={styles.closeComment}
                onClick={this.onCloseLinkingReference}
              >
                <img src={Close} />
              </span>
              <div className={styles.chooseDocumentHeader}>
                {this.state.choosingAnnotation && (
                  <img
                    src={backArrow}
                    onClick={() => this.goBack()}
                    className={styles.backArrow}
                  />
                )}
                Link Reference
              </div>
            </div>
            <div
              className={`${
                this.state.choosingAnnotation
                  ? styles.stageTwoDetailsContainer
                  : styles.stageOneDetailsContainer
              } ${filteredResults.length === 0 ? styles.stepsBlock : ""}`}
            >
              <div className={styles.statusContainer}>
                <div className={styles.stepIconContainer}>
                  <img src={StepOneFilled} />
                  <img
                    className={styles.line}
                    src={this.state.choosingAnnotation ? lineGreen : lineWhite}
                  />
                  <img
                    src={
                      this.state.choosingAnnotation
                        ? StepTwoFilled
                        : StepTwoOutline
                    }
                  />
                </div>
                <div className={styles.statusSteps}>
                  <p
                    className={
                      this.state.choosingAnnotation
                        ? styles.stepOneTextDisabled
                        : styles.stepOneTextActive
                    }
                  >
                    Choose Reference Document
                  </p>
                  <p
                    className={
                      this.state.choosingAnnotation
                        ? styles.stepTwoTextActive
                        : styles.stepTwoTextDisabled
                    }
                  >
                    Choose Reference Citation
                  </p>
                </div>
              </div>
              <div className={styles.searchInputContainer}>
                <UikInput
                  placeholder={
                    this.state.choosingAnnotation
                      ? "Search Annotations"
                      : "Search References"
                  }
                  className={styles.searchInputField}
                  onChange={this.onSearchChange}
                  value={this.state.searchValue}
                ></UikInput>
              </div>
            </div>
            {filteredResults.length === 0 && (
              <div
                className={`${styles.emptyStateContainer} ${
                  this.state.referenceAnnotations
                    ? styles.emptyStateContainerAnnotations
                    : ""
                }`}
              >
                <img
                  className={styles.emptyStateUploaderIcon}
                  src={
                    this.state.referenceAnnotations ? linkIcon : noUploadIcon
                  }
                ></img>
                <span className={styles.emptyStateMessage}>
                  {this.state.referenceAnnotations
                    ? "There are no annotations."
                    : "There are no reference documents."}
                </span>
                <Button
                  original
                  text={
                    this.state.referenceAnnotations
                      ? "Create New Annotation"
                      : "Add reference"
                  }
                  onClick={
                    this.state.referenceAnnotations
                      ? this.createNewAnnotation
                      : this.props.showReferencesLibrary
                  }
                />
              </div>
            )}
            {!this.state.referenceAnnotations && filteredResults.length > 0 && (
              <div className={styles.sidePanelOptions}>
                {this.state.searchValue && (
                  <div className={styles.resultsHeader}>Results</div>
                )}
                {filteredResults.map((ref, idx) => {
                  return this.renderReferenceDocument(ref, idx);
                })}
              </div>
            )}
            {this.state.referenceAnnotations && (
              <div className={styles.sidePanelOptions}>
                {this.state.searchValue && (
                  <div className={styles.resultsHeader}>Results</div>
                )}
                {this.renderReferencesList(filteredResults)}
              </div>
            )}
            {filteredResults.length > 0 && (
              <div className={styles.addReferenceDocumentButtonContainer}>
                <div className={styles.addReferenceDocumentButton}>
                  <Button
                    original
                    text={
                      this.state.referenceAnnotations
                        ? "Create New Annotation"
                        : "Add Reference Document"
                    }
                    onClick={
                      this.state.referenceAnnotations
                        ? this.createNewAnnotation
                        : this.props.showReferencesLibrary
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  showDeleteAllLinkedModal = (docRefId) => {
    this.setState({ showDeleteAllLinkedDocumentRefsModal: true });
  };

  cancelDeleteAllLinkedModal = () => {
    this.setState({ showDeleteAllLinkedDocumentRefsModal: false });
  };

  showDeleteModal = (docRefId) => {
    this.setState({
      showDocumentReferenceDeleteModal: true,
      docRefToDelete: docRefId,
    });
  };

  cancelDelete = () => {
    this.setState({
      showDocumentReferenceDeleteModal: false,
      docRefToDelete: null,
    });
  };

  backArrowClicked = () => {
    this.props.showDocumentReferencePanel();
    this.onCloseLinkingReference();
  };

  showLinkedReferenceAnnotations = () => {
    return (
      <div className={styles.referencePanel}>
        {this.state.groupedDocumentReferences !== null &&
          this.state.groupedDocumentReferences.length > 0 &&
          !this.state.addToExistingReferencePanel &&
          !this.props.inRedefineMode && (
            <div>
              <div className={styles.sidePanelHeader}>
                <span
                  className={styles.backArrow}
                  onClick={() => {
                    this.backArrowClicked();
                  }}
                >
                  <img src={leftarrowSVG} />
                </span>
                <span className={styles.sidePanelHeadingTitle}>
                  Reference Citations
                </span>
                <span
                  className={styles.closeComment}
                  onClick={() => this.props.close()}
                >
                  &times;
                </span>
                {security.getUserRole() !== "viewer" &&
                  !this.props.isApproved &&
                  this.props.viewingCurrentVersion &&
                  this.state.groupedDocumentReferences !== null &&
                  this.state.groupedDocumentReferences.length > 0 && (
                    <div className={styles.referenceActionsDropDownContainer}>
                      <UikDropdown
                        DisplayComponent={this.actionsDropDown}
                        position="bottomRight"
                      >
                        <UikDropdownItem
                          onClick={() =>
                            this.setState({ showRedefineModal: true })
                          }
                        >
                          Redefine Annotation
                        </UikDropdownItem>
                        <UikDropdownItem
                          onClick={() =>
                            this.setState({
                              showDeleteAllLinkedDocumentRefsModal: true,
                            })
                          }
                        >
                          Delete All Citations
                        </UikDropdownItem>
                      </UikDropdown>
                    </div>
                  )}
              </div>
              <div></div>
              <div className={styles.sidePanelOptionsDocRefs}>
                {this.state.groupedDocumentReferences.map((ref, idx) => {
                  return this.renderDocumentReference(ref, idx);
                })}
              </div>
              {!this.props.readOnly && !this.props.inRedefineMode && (
                <div className={styles.addReferenceButton}>
                  <Button
                    original
                    text="Add to Reference"
                    onClick={this.props.onAddToReference}
                  />
                </div>
              )}
            </div>
          )}
        <NewModal
          visible={this.state.showDocumentReferenceDeleteModal}
          type="alert"
        >
          <div className="heading">Delete Reference Citation</div>
          <UikDivider />
          <div className="body">
            {`Are you sure you want to delete this reference? You cannot undo this action.`}
          </div>
          <UikDivider />
          <div className="buttons">
            <UikButton
              onClick={() => this.onDeleteReference(this.state.docRefToDelete)}
              primary
            >
              Delete
            </UikButton>
            <UikButton onClick={() => this.cancelDelete()}>Cancel</UikButton>
          </div>
        </NewModal>
        <NewModal
          visible={this.state.showDeleteAllLinkedDocumentRefsModal}
          type="alert"
        >
          <div className="heading">Delete Linked Reference Citations</div>
          <UikDivider />
          <div className="body">
            {`Are you sure you want to delete all references linked to this annotation? You cannot undo this action.`}
          </div>
          <UikDivider />
          <div className="buttons">
            <UikButton onClick={() => this.deleteAllLinkedReferences()} primary>
              Delete
            </UikButton>
            <UikButton onClick={() => this.cancelDeleteAllLinkedModal()}>
              Cancel
            </UikButton>
          </div>
        </NewModal>
        <NewModal visible={this.state.showRedefineModal} type="alert">
          <div className="heading">Redefine your Reference Annotation</div>
          <UikDivider />
          <div className="body">
            {`Highlight or select a new location for where you want to move your annotation.`}
          </div>
          <UikDivider />
          <div className="buttons">
            <UikButton
              onClick={() => this.startRedefiningDocumentReference()}
              primary
            >
              OK
            </UikButton>
          </div>
        </NewModal>
        {this.props.inRedefineMode && (
          <div>
            <div className={styles.sidePanelHeader}>
              <span
                className={styles.backArrow}
                onClick={() => {
                  this.undoRedefiningDocumentReference();
                }}
              >
                <img src={leftarrowSVG} />
              </span>
              <span className={styles.sidePanelHeadingTitle}>Redefining</span>
              <span
                className={styles.closeComment}
                onClick={() => this.undoRedefiningDocumentReference()}
              >
                &times;
              </span>
            </div>
            {this.renderRedefinedDocumentReferences()}
          </div>
        )}
        {this.props.inRedefineMode && (
          <div className={styles.editControlsContainer}>
            <UikButton
              className={styles.cancelEditButton}
              onClick={() => {
                this.undoRedefiningDocumentReference();
              }}
            >
              <img className={styles.cancelButton} src={CloseSVG} />
              Cancel
            </UikButton>
            <UikButton
              disabled={!this.props.redefinedXFDFId}
              className={styles.applyChangesButton}
              primary
              onClick={() => {
                this.updateRedefinedDocumentReference();
              }}
            >
              Apply Changes
            </UikButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    if (this.props.linkingReference) {
      return this.linkReferenceAnnotation();
    } else {
      return this.showLinkedReferenceAnnotations();
    }
  }
}

ReferencePanelBuild.propTypes = {
  referenceBlockId: PropTypes.string,
  close: PropTypes.func,
  docId: PropTypes.number,
  pageNumber: PropTypes.string,
  legacyDocument: PropTypes.bool,
  isApproved: PropTypes.bool,
  currentXfdfHighlightId: PropTypes.string,
  currentXfdfHighlightString: PropTypes.string,
};

const ReferencePanel = createForm()(ReferencePanelBuild);

const mapStateToProps = (state) => {
  return {
    referenceDocuments: state.referenceDocuments,
    documentReferences: state.documentReferences,
  };
};

const actionCreators = {
  fetchReferenceDocuments,
  fetchDocumentReferences,
};

export default connect(mapStateToProps, actionCreators)(ReferencePanel);
