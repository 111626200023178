import React, { useEffect, useState, useRef } from "react";
import Lottie from "react-lottie";
import security from "../../../services/Security";
import httpClient from "../../../lib/HttpClient";
import Header from "../../shared/Header/Header";
import VersionComparePanel from "./VersionComparePanel.js";
import { Container } from "react-grid-system";
import { loadDocumentUrl } from "../../../lib/axios-client";
import WebViewer from "@pdftron/webviewer";
import warning from "../../../images/icons/svg/warning-sign.svg";
import loadingAnimation from "../../../images/lotties/loading.json";

import styles from "./CompareDocuments.module.scss";

export default (props) => {
  const [versionsToCompare, setVersionsToCompare] = useState([
    props.match.params.doc1_id,
    props.match.params.doc2_id,
  ]);

  const [versions, setVersions] = useState([]);

  const [existingWebViewerInstance, setExistingWebViewerInstance] =
    useState(null);

  const [versionOneFileExtension, setVersionOneFileExtension] = useState(null);

  const [versionTwoFileExtension, setVersionTwoFileExtension] = useState(null);

  const [showError, setShowError] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let fileExtensionOne;
    let fileExtensionTwo;
    httpClient
      .get(`/documents/${props.match.params.doc1_id}/versions.json`)
      .then((response) => {
        setShowError(false);
        const versions = response.data;

        fileExtensionOne = identifyFileExtension(
          versions,
          props.match.params.doc1_id
        );
        fileExtensionTwo = identifyFileExtension(
          versions,
          props.match.params.doc2_id
        );

        setVersionOneFileExtension(fileExtensionOne);
        setVersionTwoFileExtension(fileExtensionTwo);
        setVersions(versionsWithVideosFlagged(versions));

        const fileExtensions = [fileExtensionOne, fileExtensionTwo];

        return fileExtensions;
      })
      .then((fileExtensions) => {
        const webViewerOptions = {
          path: "/vendor/pdf/lib",
          fullAPI: true,
          disabledElements: [
            "notesPanelResizeBar",
            "filterAnnotationButton",
            "notesOrderDropdown",
            "ribbons",
            "outlinesPanelButton",
            "signaturePanelButton",
            "thumbDelete",
            "thumbRotateClockwise",
            "leftPanelResizeBar",
            "documentControl",
            "searchPanelResizeBar",
            "fullscreenButton",
            "printButton",
            "themeChangeButton",
            "deletePage",
            "insertPageBelow",
            "insertPageAbove",
            "pageInsertionHeader",
          ],
          css: "/vendor/pdf/lib/ui/CompareVersionsPDFtronStyle.css",
          isAdminUser: true,
          licenseKey: `${process.env.REACT_APP_PDFTRON_LICENSE_KEY}`,
        };

        if (existingWebViewerInstance) {
          loadNewComparison(null, fileExtensions[0], fileExtensions[1]);
        } else {
          // This is only used for docx comparisons.
          WebViewer(webViewerOptions, viewer2.current).then((instance) => {});

          WebViewer(webViewerOptions, viewer3.current).then((instance) => {});

          // This is the main WebViewer instance.
          WebViewer(webViewerOptions, viewer.current).then((instance) => {
            setShowLoader(true);
            setExistingWebViewerInstance(instance);
            loadNewComparison(instance, fileExtensions[0], fileExtensions[1]);

            const style =
              instance.UI.iframeWindow.document.documentElement.style;
            style.setProperty(`--blue-5`, "#1b8c96");
            style.setProperty(`--blue-6`, "#1b8c96");
            style.setProperty(`--gray-8`, "#868e96");

            instance.updateElement("selectToolButton", {
              img: "/assets/svg/default-select.svg",
            });

            instance.updateElement("panToolButton", {
              img: "/assets/svg/default-pan.svg",
            });

            instance.updateElement("viewControlsButton", {
              img: "/assets/svg/default-viewcontrols.svg",
            });

            instance.updateElement("toggleNotesButton", {
              img: "/assets/svg/diff-panel.svg",
            });

            instance.updateElement("menuButton", {
              img: "/assets/svg/diff-panel-settings.svg",
            });
          });
        }
      })
      .catch((error) => {
        setShowError(true);
      });
  }, [versionsToCompare]);

  let viewer = useRef(null);
  let viewer2 = useRef(null);
  let viewer3 = useRef(null);

  const token = security.getToken();

  const identifyFileExtension = (versions, versionId) => {
    return versions.find((v) => v.id.toString() === versionId).file_extension;
  };

  const refreshTempInstances = async () => {
    const webViewerOptions = {
      path: "/vendor/pdf/lib",
      fullAPI: true,
      disabledElements: [
        "notesPanelResizeBar",
        "filterAnnotationButton",
        "notesOrderDropdown",
        "ribbons",
        "outlinesPanelButton",
        "signaturePanelButton",
        "thumbDelete",
        "thumbRotateClockwise",
        "leftPanelResizeBar",
        "documentControl",
        "searchPanelResizeBar",
        "fullscreenButton",
        "printButton",
        "themeChangeButton",
        "deletePage",
        "insertPageBelow",
        "insertPageAbove",
        "pageInsertionHeader",
      ],
      css: "/vendor/pdf/lib/ui/CompareVersionsPDFtronStyle.css",
      isAdminUser: true,
      licenseKey: `${process.env.REACT_APP_PDFTRON_LICENSE_KEY}`,
    };

    const primaryInstance = await window.WebViewer.getInstance(
      document.getElementById("webViewer")
    );
    await primaryInstance.UI.closeDocument();

    const additionalInstance1 = await document.getElementById("webViewer2");
    await additionalInstance1.remove();

    const additionalInstance2 = await document.getElementById("webViewer3");
    await additionalInstance2.remove();

    let tempInstance1 = await document.createElement("DIV");
    tempInstance1.style.display = "none";
    await tempInstance1.setAttribute("id", "webViewer2");
    await document.body.appendChild(tempInstance1);

    let tempInstance2 = await document.createElement("DIV");
    tempInstance2.style.display = "none";
    await tempInstance2.setAttribute("id", "webViewer3");
    await document.body.appendChild(tempInstance2);

    await window
      .WebViewer(webViewerOptions, document.getElementById("webViewer2"))
      .then((instance) => {});

    await window
      .WebViewer(webViewerOptions, document.getElementById("webViewer3"))
      .then((instance) => {});
  };

  const changeVersionsToCompare = async (versionOne, versionTwo) => {
    await refreshTempInstances();
    setVersionsToCompare([versionOne, versionTwo]);
  };

  const swapComparisonOrder = async () => {
    if (!showError) {
      const swappedVersionOrder = versionsToCompare.reverse();

      await refreshTempInstances();
      setVersionsToCompare(swappedVersionOrder);
      loadNewComparison(null, versionOneFileExtension, versionTwoFileExtension);
    }
  };

  const loadNewComparison = async (
    newInstance,
    fileExtensionOne,
    fileExtensionTwo
  ) => {
    setShowLoader(true);
    const instance = newInstance ? newInstance : existingWebViewerInstance;

    const baseURL = `${window.location.protocol + "//" + window.location.host}`;

    window.history.pushState(
      null,
      "",
      `${baseURL}/compare/${versionsToCompare[0]}/${versionsToCompare[1]}`
    );

    const { documentViewer, PDFNet, office2PDFBuffer } = instance.Core;

    await PDFNet.initialize();

    const newDoc = await PDFNet.PDFDoc.create();
    await newDoc.lock();

    let instance2 = window.WebViewer.getInstance(
      document.getElementById("webViewer2")
    );

    let instance3 = window.WebViewer.getInstance(
      document.getElementById("webViewer3")
    );

    let buff1 = await instance2.Core.office2PDFBuffer(
      `${loadDocumentUrl()}/documents/${
        versionsToCompare[0]
      }/file${fileExtensionOne}`,
      {
        customHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let buff2 = await instance3.Core.office2PDFBuffer(
      `${loadDocumentUrl()}/documents/${
        versionsToCompare[1]
      }/file${fileExtensionTwo}`,
      {
        customHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const doc1 = await PDFNet.PDFDoc.createFromBuffer(buff1);
    const doc2 = await PDFNet.PDFDoc.createFromBuffer(buff2);

    await newDoc.appendTextDiffDoc(doc1, doc2);
    await newDoc.unlock();

    instance.UI.loadDocument(newDoc);

    // wait until the document has been loaded
    documentViewer.addEventListener("documentLoaded", () => {
      setShowLoader(false);
      instance.UI.setLayoutMode(instance.UI.LayoutMode.FacingContinuous);
      instance.UI.openElements(["notesPanel"]);
    });
  };

  const versionsWithVideosFlagged = (versions) => {
    let flaggedVersions = [];

    versions.map((version) => {
      if (!version.is_video) {
        const versionLabel = {
          value: version.id,
          label: `Version ${version.version_number}`,
        };

        flaggedVersions.push(versionLabel);
      } else {
        const modifiedVersionLabel = {
          value: version.id,
          label: `Version ${version.version_number} - Cannot Compare Videos`,
          isDisabled: true,
        };

        flaggedVersions.push(modifiedVersionLabel);
      }
    });

    return flaggedVersions;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container fluid className={styles.documentViewer}>
      <div className={styles.headerContainer}>
        <Header />
      </div>
      <VersionComparePanel
        changeVersionsToCompare={changeVersionsToCompare}
        swapComparisonOrder={swapComparisonOrder}
        versions={versions}
        v1={versionsToCompare[0]}
        v2={versionsToCompare[1]}
        error={showError}
      />
      <div className={styles.webViewerContainer} id="webViewerContainer">
        <div id="webViewer" className={styles.webViewer} ref={viewer}>
          {showError && (
            <div className={styles.errorStateContainer}>
              <div className={styles.errorState}>
                <img src={warning} />
                <p className={styles.warningLineOne}>
                  Oops, there was a problem
                </p>
                <p className={styles.warningLineTwo}>
                  There was an issue loading a version, please try again.
                </p>
              </div>
            </div>
          )}
          {showLoader && (
            <div className={styles.loaderContainer}>
              <Lottie options={defaultOptions} height={92} width={82} />
            </div>
          )}
        </div>
      </div>
      <div id="webViewer2" style={{ display: "none" }} ref={viewer2}></div>
      <div id="webViewer3" style={{ display: "none" }} ref={viewer3}></div>
    </Container>
  );
};
