import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import security from "../../../../services/Security";
import Trash from "../../../../images/icons/svg/trash-teal.svg";
import Pindrop from "../../../../images/icons/svg/pindrop.svg";
import referencesService from "../../../../services/References";
import notificationService from "../../../../services/Notifications";
import httpClient from "../../../../lib/HttpClient";
import NewModal from "../../../Common/Modal";
import { Link } from "react-router-dom";
import fetchDocumentReferences from "../../../../actions/document/fetchDocumentReferences";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";

import styles from "./DocumentReferenceCard.module.scss";

export default (props) => {
  const {
    referenceCitationCount,
    page,
    index,
    documentReference,
    versionId,
    versionNumber,
    originalDocumentId,
    isApproved,
    readOnly,
    onAnnotationSelect,
    editingDocumentReferences,
    docId,
  } = props;

  const [
    showDocumentReferenceDeleteModal,
    setShowDocumentReferenceDeleteModal,
  ] = useState(false);
  const [docRefToDelete, setDocRefToDelete] = useState(null);

  const dispatch = useDispatch();

  const showDeleteModal = (ref) => {
    setShowDocumentReferenceDeleteModal(true);
    setDocRefToDelete(ref);
  };

  const hideDeleteModal = () => {
    setShowDocumentReferenceDeleteModal(false);
    setDocRefToDelete(null);
  };

  const viewReferences = (ref) => {
    if (!editingDocumentReferences) {
      const webViewerEl = document.getElementById("webViewer");
      const instance = window.WebViewer.getInstance(webViewerEl);
      const annotation = instance.annotManager.getAnnotationById(
        ref.xfdf_highlight_id
      );

      if (annotation) {
        const annotationPageNumber = annotation.getPageNumber();

        onAnnotationSelect(annotationPageNumber, ref.xfdf_highlight_id, true);

        instance.annotManager.deselectAllAnnotations();
        instance.annotManager.selectAnnotation(annotation);
        instance.docViewer.setCurrentPage(annotationPageNumber);
        instance.annotManager.jumpToAnnotation(annotation);
      } else {
        onAnnotationSelect(ref.page.page_number, ref.xfdf_highlight_id, true);
      }
    }
  };

  const onDeleteReference = (referenceToDelete) => {
    const referenceXfdfHighlightId = referenceToDelete.xfdf_highlight_id;
    setShowDocumentReferenceDeleteModal(false);
    setDocRefToDelete(null);
    httpClient
      .delete(`/document_references/${referenceToDelete.id}.json`)
      .then((response) => {
        httpClient
          .get(`/document/references/${referenceXfdfHighlightId}.json`)
          .then((response) => {
            dispatch(fetchDocumentReferences(docId));
            if (response.data.length === 0) {
              referencesService.decrementReferences();
              if (referenceXfdfHighlightId) {
                const webViewerEl = document.getElementById("webViewer");
                const annotManager =
                  window.WebViewer.getInstance(
                    webViewerEl
                  ).docViewer.getAnnotationManager();
                const annot = annotManager.getAnnotationById(
                  referenceXfdfHighlightId
                );

                annotManager.deleteAnnotation(annot);
              }
            }
          });

        notificationService.addNotification(
          "Reference removed.",
          "Reference has been deleted.",
          "success"
        );
      })
      .catch((error) => {
        security.checkAuth(error);
        notificationService.addNotification(
          "Reference deletion not allowed.",
          "You do not have permission to delete this reference.",
          "danger"
        );
      });
  };

  const renderDocumentReference = (ref) => {
    return (
      <div
        key={ref.reference.document_id}
        className={
          editingDocumentReferences
            ? styles.documentReferenceCardEditMode
            : styles.documentReferenceCard
        }
      >
        <div className={styles.documentReferenceCardSubContainer}>
          <div className={styles.topContainer}>
            <div className={styles.labelContainer}>
              <div
                className={
                  ref.reference.document.reference_document_label &&
                  ref.reference.label
                    ? styles.attachmentReferenceId
                    : styles.emptyAttachmentReferenceId
                }
              >
                {`Annotation ${index + 1}, pg ${page}`}
              </div>
            </div>
            {/* {security.getUserRole() !== "viewer" &&
              !readOnly &&
              !editingDocumentReferences && (
                <div
                  className={styles.deleteContainer}
                  onClick={() => {
                    showDeleteModal(ref);
                  }}
                >
                  <img className={styles.trashContainer} src={Trash} />
                  Delete
                </div>
              )} */}
          </div>
          <div
            onClick={() => viewReferences(ref)}
            key={ref.reference.document_id}
          >
            <div className={styles.documentReferenceCardSubContainer}>
              {!ref.caption || ref.caption === "null" ? (
                <div className={styles.pindropCaptionContainer}>
                  <img className={styles.pinIcon} src={Pindrop} />
                  <div className={styles.pinReferenceCaption}>
                    {"Pindrop Reference"}
                  </div>
                </div>
              ) : (
                <div className={styles.captionContainer}>
                  <div className={styles.linkingReferenceCaption}>
                    {ref.caption}
                  </div>
                </div>
              )}
              <div className="clear"></div>
              <div className={styles.bottomContainer}>
                {!editingDocumentReferences && (
                  <div
                    onClick={() => viewReferences(ref)}
                    className={styles.attachmentDocumentLink}
                  >
                    {referenceCitationCount === 1
                      ? "View Reference Citation"
                      : `View ${referenceCitationCount} Reference Citations`}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderDocumentReference(documentReference)}
      <NewModal visible={showDocumentReferenceDeleteModal} type="alert">
        <div className="heading">Delete Reference</div>
        <UikDivider />
        <div className="body">
          {`Are you sure you want to delete this reference? You cannot undo this action.`}
        </div>
        <UikDivider />
        <div className="buttons">
          <UikButton onClick={() => onDeleteReference(docRefToDelete)} primary>
            Delete
          </UikButton>
          <UikButton onClick={() => hideDeleteModal()}>Cancel</UikButton>
        </div>
      </NewModal>
    </div>
  );
};
