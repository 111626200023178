import React from "react";
import PropTypes from "prop-types";
import { createForm, formShape } from "rc-form";
import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";

import styles from "./StructuredContentNav.module.scss";

import security from "../../../services/Security";
import Setup2FA from "../../2FA/Setup2FA";
import httpClient from "../../../lib/HttpClient";
import history from "../../../history";
import notificationService from "../../../services/Notifications";
import RadioButton from "../../Common/RadioButton";

import papercurveLogo from "../../../images/logos/papercurve-logo-colour.svg";
import claimsIcon from "../../../images/icons/svg/claims-icon-small.svg";
import textIcon from "../../../images/icons/svg/text-category-icon.svg";
import imageIcon from "../../../images/icons/svg/image-category-icon.svg";
import newCategoryIcon from "../../../images/icons/svg/new-category-icon.svg";
import accountSetting from "../../../services/AccountSetting";

import NewModal from "../../Common/Modal";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

class StructuredContentNavBuild extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole,
      },
      categoryName: "",
      categoryLabel: "",
      categoryType: "text",
      categoryStatus: true,
      categories: [],
      selectedCategory: {},
      categoryLookup: "",
      nameError: "",
      labelError: "",
      structuredContentPropertyGroups: [],
    };
  }

  componentWillMount = () => {
    httpClient
      .get(`/structured_content_property_groups.json`)
      .then((response) => {
        this.setState({ structuredContentPropertyGroups: response.data });
      });
  };

  toggleEditableFieldStatus = (editable) => {
    this.setState({ categoryStatus: editable });
  };

  handlePropertyGroupSelection = (e) => {
    this.setState({ selectedPropertyGroup: parseInt(e.target.value) });
  };

  renderPropertyGroups = () => {
    return this.state.structuredContentPropertyGroups.map((scpg) => {
      return (
        <div className={styles.radiobuttonContainer}>
          <RadioButton
            name="role"
            label={`${scpg.name}`}
            value={`${scpg.id}`}
            onChange={(e) => this.handlePropertyGroupSelection(e)}
            checked={this.state.selectedPropertyGroup === scpg.id}
          />
        </div>
      );
    });
  };

  handleSave = (e) => {
    let formData = {
      name: this.state.categoryName,
      label_prefix: this.state.categoryLabel,
      active: this.state.categoryStatus,
      column_type: this.state.categoryType,
      lookup: this.state.categoryLookup,
    };

    if (this.state.selectedPropertyGroup) {
      formData["structured_content_property_group_id"] =
        this.state.selectedPropertyGroup;
    }

    httpClient
      .post(`/structured_content_categories.json`, formData)
      .then((response) => {
        console.log("saved!");
        notificationService.addNotification(
          "Structured Content Category Saved",
          "Your changes to this category has been saved",
          "success"
        );
        //reloadCategories();
        httpClient
          .get(`/structured_content_categories.json`)
          .then((response) => {
            this.props.setCategories(response.data);
          });
        history.push(`/structured_content/`);
      })
      .catch((error) => {
        let prefixErrorMessage = "";

        let nameErrorMessage = "";

        if (error.response.data.label_prefix) {
          if (error.response.data.label_prefix[0] === "can't be blank") {
            notificationService.addNotification(
              "Please enter a Label Prefix",
              "Please enter a Label Prefix",
              "warning"
            );
            prefixErrorMessage = "Please enter a Label Prefix";
          } else {
            notificationService.addNotification(
              "This Label Prefix already exists",
              "This Label Prefix already exists",
              "warning"
            );
            prefixErrorMessage = "This Label Prefix already exists";
          }
        }

        if (error.response.data.name) {
          if (error.response.data.name[0] === "can't be blank") {
            notificationService.addNotification(
              "Please enter a Category Name",
              "Please enter a Category Name",
              "warning"
            );
            nameErrorMessage = "Please enter a Category Name";
          } else {
            notificationService.addNotification(
              "This Category Name already exists",
              "This Category Name already exists",
              "warning"
            );
            nameErrorMessage = "This Category Name already exists";
          }
        }
        this.setState({
          nameError: nameErrorMessage,
          labelError: prefixErrorMessage,
        });
      });
  };

  handleCategoryNameChange = (e) => {
    const formattedLookup = e.target.value.toLowerCase().split(" ").join("_");
    this.setState({
      categoryName: e.target.value,
      categoryLookup: formattedLookup,
      nameError: "",
    });
  };

  generateCategoryLabel = (e) => {
    let splitLabel = e.target.value.split(" ");
    let generatedLabel;

    if (splitLabel.length > 1) {
      generatedLabel = splitLabel
        .map((word, index) => {
          if (index <= 3) {
            return word[0];
          }
        })
        .join("")
        .toUpperCase();
    } else {
      let firstFourCharacters = e.target.value
        .split("")
        .map((char, index) => {
          if (index <= 3) {
            return char;
          }
        })
        .join("");

      generatedLabel = firstFourCharacters.toUpperCase();
    }

    this.setState({
      categoryLabel: generatedLabel,
      labelError: "",
    });
  };

  handleLabelChange = (e) => {
    this.setState({
      labelError: "",
      categoryLabel: e.target.value,
    });
  };

  renderCategoryIcon = (category) => {
    if (category.column_type === "product_claim") {
      return <img className={styles.categoryIcon} src={claimsIcon} />;
    } else if (category.column_type === "text") {
      return <img className={styles.categoryIcon} src={textIcon} />;
    } else if (category.column_type === "image") {
      return <img className={styles.categoryIcon} src={imageIcon} />;
    }
  };

  render() {
    const { t, selectedCategoryId } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    const new_category = window.location.pathname.match(
      /^\/structured_content\/categories\/new/
    )
      ? true
      : false;

    const product_claims = window.location.pathname.match(
      /^\/structured_content\/product_claims/
    )
      ? true
      : false;

    return (
      <Col md={2} className={styles.leftSideMenuContainer}>
        <div className={styles.leftSideMenu}>
          <div className={[styles.leftSideMenuHeader].join(" ")}>
            {t("structured_content.structured_content")}
          </div>
          {this.props.categories.map((category, index) => (
            <div className={styles.categoryContainer}>
              {category.column_type === "product_claim" && (
                <div
                  onClick={() =>
                    history.push(`/structured_content/product_claims`)
                  }
                  className={`${styles.leftSideMenuItem} ${
                    product_claims ? styles.selected : ""
                  }`}
                >
                  <div>
                    {this.renderCategoryIcon(category)}
                    {t("structured_content.product_claims")}
                  </div>
                  <div className={styles.countPill}>
                    {category.remaining_structured_content_count}
                  </div>
                </div>
              )}
              {category.column_type !== "product_claim" && (
                <div
                  onClick={() =>
                    history.push(
                      `/structured_content/category/${category.id}`,
                      { params: { id: category.id } }
                    )
                  }
                  className={`${styles.leftSideMenuItem} ${
                    selectedCategoryId == category.id ? styles.selected : ""
                  }`}
                >
                  <div>
                    {this.renderCategoryIcon(category)}
                    {category.name}
                  </div>
                  <div className={styles.countPill}>
                    {category.remaining_structured_content_count}
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className={styles.newCategory}>
            <div
              onClick={() => history.push(`/structured_content/categories/new`)}
              className={`${styles.leftSideMenuItem} ${
                new_category ? styles.selected : ""
              }`}
            >
              <div className={styles.newCategoryButton}>
                <img className={styles.newCategoryIcon} src={newCategoryIcon} />
                {t("structured_content.new_category")}
              </div>
            </div>
          </div>
        </div>
        <NewModal
          hideModal={!new_category}
          visible={new_category}
          type="regular"
        >
          <div className="heading">{t("structured_content.new_category")}</div>
          <UikDivider />
          <div className="body">
            <div>
              <UikFormInputGroup className={styles.formContainer}>
                <div className={styles.newCategoryFormCol}>
                  {getFieldDecorator(`categoryName`, {
                    rules: [
                      {
                        required: true,
                        message: "Please enter a category name.",
                      },
                    ],
                  })(
                    <UikInput
                      onChange={(e) => {
                        this.handleCategoryNameChange(e);
                      }}
                      className={
                        this.state.nameError
                          ? styles.inputFieldContainerError
                          : styles.inputFieldContainer
                      }
                      label="CATEGORY NAME"
                      placeholder=""
                      onBlur={(e) => {
                        this.generateCategoryLabel(e);
                      }}
                    />
                  )}
                  {this.state.nameError && (
                    <p className={styles.errorMessage}>
                      {this.state.nameError}
                    </p>
                  )}
                  <UikInput
                    onChange={(e) => {
                      this.handleLabelChange(e);
                    }}
                    className={
                      this.state.labelError
                        ? styles.inputFieldContainerError
                        : styles.inputFieldContainer
                    }
                    label="LABEL PREFIX"
                    placeholder=""
                    value={this.state.categoryLabel}
                  />
                  {this.state.labelError && (
                    <p className={styles.errorMessage}>
                      {this.state.labelError}
                    </p>
                  )}
                  {this.state.structuredContentPropertyGroups.length > 0 &&
                    this.state.categoryType === "text" && (
                      <p className={styles.formLabel}>preset fields</p>
                    )}
                  {this.state.structuredContentPropertyGroups.length > 0 &&
                    this.state.categoryType === "text" &&
                    this.renderPropertyGroups()}
                  <div className={styles.customInputContainer}>
                    {/* <div className={styles.customInputLabel}>
                      Editable Fields
                    </div>
                    <div>
                      <UikToggle
                        defaultChecked={!this.state.editableFieldStatus}
                        onChange={(e) =>
                          this.toggleEditableFieldStatus(e.target.checked)
                        }
                      />
                      <span className={styles.editableFieldsActive}>
                        Active
                      </span>
                    </div> */}
                    <div className={styles.statusDescription}>
                      The placeholder for this category is:
                      <br />
                      {`{{structured:${this.state.categoryLookup}}}`}
                      <br />
                      <br />
                      If you would like to include more than one instance of it,
                      simply append a field name to it like so:
                      <br />
                      {`{{structured:${this.state.categoryLookup}:editable_field_name}}`}
                      <div
                        className={styles.kbLink}
                        onClick={() => {
                          window.open(
                            `https://help.papercurve.com/knowledge-base/structured-content-authoring`
                          );
                        }}
                      >
                        Learn more
                      </div>
                    </div>
                  </div>
                  <div className={styles.customInputContainer}>
                    <div className={styles.customInputLabel}>Category Type</div>
                    <div className={styles.categoryTypeSelection}>
                      <div
                        className={`${styles.categoryType} ${
                          this.state.categoryType === "text"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => this.setState({ categoryType: "text" })}
                      >
                        <img className={styles.categoryIcon} src={textIcon} />
                        Text
                      </div>
                      <div
                        className={`${styles.categoryType} ${
                          this.state.categoryType === "image"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => this.setState({ categoryType: "image" })}
                      >
                        <img className={styles.categoryIcon} src={imageIcon} />
                        Image
                      </div>
                    </div>
                  </div>
                </div>
              </UikFormInputGroup>
            </div>
          </div>
          <div className="clear" />
          <div className="dividerContainer">
            <UikDivider />
          </div>
          <div className="buttons">
            <UikButton onClick={(e) => this.handleSave(e)} primary>
              Save
            </UikButton>
            <UikButton onClick={() => history.push(`/structured_content`)}>
              Cancel
            </UikButton>
          </div>
        </NewModal>
      </Col>
    );
  }
}

const StructuredContentNav = withTranslation()(
  createForm()(StructuredContentNavBuild)
);

export default StructuredContentNav;
