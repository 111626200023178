import React from "react";
import moment from "moment";
import security from "../../services/Security";
import inReviewEmptyState from "../../images/icons/svg/in-review-empty-state.svg";
import notificationIndicator from "../../images/icons/svg/notification-indicator.png";
import favouriteIcon from "../../images/icons/svg/star-inactive.svg";
import favouriteIconActive from "../../images/icons/svg/star-active.svg";
import { useTranslation } from "react-i18next";
import { Line } from "rc-progress";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import loadingAnimation from "../../images/lotties/loading.json";
import { UikButton } from "@uik";
import ArrowUpIcon from "../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../images/icons/svg/sorting-arrow-down.svg";
import preview from "../../images/icons/svg/eye-light-gray.svg";
import previewHover from "../../images/icons/svg/eye-dark-gray.svg";
import loading from "../../images/icons/svg/loading-yellow.svg";
import httpClient from "../../lib/HttpClient";

import styles from "./DashboardTable.module.scss";

export default (props) => {
  const {
    documentsInReview,
    noSearchResults,
    sortedDocs,
    setShowDashboardPreviewModal,
    setSelectedPreview,
    showLoader,
    sortingColumnIndex,
    setSortingColumnIndex,
    sortOrder,
    setSortOrder,
    sort,
  } = props;

  const { useState, useEffect, createRef } = React;

  const [elRefs, setElRefs] = useState([]);
  const [tooltipRefs, setTooltipRefs] = useState([]);
  const [previewButtonRefs, setPreviewButtonRefs] = useState([]);
  const [previewButtonHiddenRefs, setPreviewButtonHiddenRefs] = useState([]);
  const [favouriteStatuses, setFavouriteStatuses] = useState({});
  const [showSpinnerForDocumentId, setShowSpinnerForDocumentId] =
    useState(null);

  useEffect(() => {
    if (sortedDocs.length > 0) {
      let refs = [];
      let tooltipRefArray = [];
      let previewButtonArray = [];
      let previewButtonHiddenArray = [];
      sortedDocs.map((link, index) => {
        refs.push(createRef());
        tooltipRefArray.push(createRef());
        previewButtonArray.push(createRef());
        previewButtonHiddenArray.push(createRef());
      });
      setElRefs(refs);
      setTooltipRefs(tooltipRefArray);
      setPreviewButtonRefs(previewButtonArray);
      setPreviewButtonHiddenRefs(previewButtonHiddenArray);
    } else if (documentsInReview.length > 0) {
      let refs = [];
      let tooltipRefArray = [];
      let previewButtonArray = [];
      let previewButtonHiddenArray = [];
      documentsInReview.map((link, index) => {
        refs.push(createRef());
        tooltipRefArray.push(createRef());
        previewButtonArray.push(createRef());
        previewButtonHiddenArray.push(createRef());
      });
      setElRefs(refs);
      setTooltipRefs(tooltipRefArray);
      setPreviewButtonRefs(previewButtonArray);
      setPreviewButtonHiddenRefs(previewButtonHiddenArray);
    }
  }, [sortedDocs, documentsInReview]);

  const { t } = useTranslation();

  const currentUserRole = security.getUserRole();

  let tableProperties = [
    "title",
    "due date",
    "progress",
    "status",
    "open document",
  ];

  const getPercentApproved = (pendingApprovals, approversNotObservers) => {
    const percentApproved =
      (1 - pendingApprovals / approversNotObservers) * 100;
    return percentApproved;
  };

  const renderApprovalProgress = (
    pendingApprovals,
    approversNotObservers,
    progressIndicator
  ) => {
    let progress;
    if (approversNotObservers === 0) {
      progress = 0;
    } else {
      progress = getPercentApproved(pendingApprovals, approversNotObservers);
    }
    return (
      <div className={styles.progress}>
        <div className={styles.progressBar}>
          <Line
            className={styles.uploadProgress}
            percent={progress}
            strokeWidth="2"
            strokeColor="#1b8c96"
            strokeLinecap="round"
            trailColor="#eaedf3"
            trailWidth="2"
          />
        </div>
        <div className={styles.progressIndicator}>{progressIndicator}</div>
      </div>
    );
  };

  const renderTitle = (title) => {
    return <div className={styles.titleContainer}>{title}</div>;
  };

  const showContent = (doc, index) => {
    if (index === 4) {
      setShowDashboardPreviewModal(true);
      setSelectedPreview(doc);
    } else {
      viewContent(doc);
    }
  };

  const onProgressBarHover = (linkIndex) => {
    elRefs[linkIndex].current.style.display = "flex";
    elRefs[linkIndex].current.style.visibility = "visible";
    tooltipRefs[linkIndex].current.style.display = "flex";
    tooltipRefs[linkIndex].current.style.visibility = "visible";
  };

  const onProgressBarLeave = (linkIndex) => {
    elRefs[linkIndex].current.style.display = "none";
    tooltipRefs[linkIndex].current.style.display = "none";
  };

  const onPreviewButtonHover = (linkIndex) => {
    previewButtonRefs[linkIndex].current.style.display = "flex";
    previewButtonRefs[linkIndex].current.style.visibility = "visible";
    previewButtonHiddenRefs[linkIndex].current.style.display = "none";
  };

  const onPreviewButtonLeave = (linkIndex) => {
    previewButtonRefs[linkIndex].current.style.display = "none";
    previewButtonHiddenRefs[linkIndex].current.style.display = "flex";
  };

  const renderPreviewIcon = (document, index) => {
    return (
      <div className={styles.openContainer} data-title={"Preview"}>
        <div
          className={styles.previewIconContainer}
          onMouseEnter={() => onPreviewButtonHover(index)}
          onMouseLeave={() => onPreviewButtonLeave(index)}
        >
          <img src={preview} ref={previewButtonHiddenRefs[index]} />
          <img
            className={styles.previewHoverState}
            src={previewHover}
            ref={previewButtonRefs[index]}
          />
        </div>
      </div>
    );
  };

  const viewContent = (document) => {
    if (document.approved) {
      window.open(`/documents/${document.id}`);
    } else {
      window.open(`/drafts/${document.id}`);
    }
  };

  const renderProgressCaption = (caption) => {
    let captionStyle;

    if (caption === "Release to Approved") {
      captionStyle = styles.releaseToApproved;
    } else if (caption === "Request New Version") {
      captionStyle = styles.requestNewVersion;
    } else if (caption === "Submit Approval") {
      captionStyle = styles.submitApproval;
    } else if (caption === "Waiting for Reviewer") {
      captionStyle = styles.awaitingApproval;
    } else if (caption === "Review Submitted") {
      captionStyle = styles.reviewSubmitted;
    } else if (caption === "Changes Requested") {
      captionStyle = styles.changesRequested;
    }
    return (
      <div className={styles.captionContainer}>
        <div className={captionStyle}>{caption}</div>
      </div>
    );
  };

  const rows = {};
  if (sortedDocs.length > 0) {
    sortedDocs.length > 0 &&
      sortedDocs.map((document, documentIndex) => {
        rows[document.id] = [];
        tableProperties.map((property, index) => {
          if (property === "title") {
            rows[document.id].push(renderTitle(document.title));
          } else if (property === "due date") {
            rows[document.id].push(document.due_date);
          } else if (property === "progress") {
            rows[document.id].push(
              renderApprovalProgress(
                document.pending_approvals,
                document.total_approvals,
                document.progress_indicator
              )
            );
          } else if (property === "status") {
            rows[document.id].push(
              renderProgressCaption(document.progress_caption)
            );
          } else if (property === "open document") {
            rows[document.id].push(renderPreviewIcon(document, documentIndex));
          }
        });
      });
  } else {
    documentsInReview.length > 0 &&
      documentsInReview.map((document, documentIndex) => {
        rows[document.id] = [];
        tableProperties.map((property, index) => {
          if (property === "title") {
            rows[document.id].push(renderTitle(document.title));
          } else if (property === "due date") {
            rows[document.id].push(document.due_date);
          } else if (property === "progress") {
            rows[document.id].push(
              renderApprovalProgress(
                document.pending_approvals,
                document.total_approvals,
                document.progress_indicator
              )
            );
          } else if (property === "status") {
            rows[document.id].push(
              renderProgressCaption(document.progress_caption)
            );
          } else if (property === "open document") {
            rows[document.id].push(renderPreviewIcon(document, documentIndex));
          }
        });
      });
  }

  const renderEmptyState = () => {
    if (showLoader) {
      return (
        <div className={styles.loaderContainer}>
          <Lottie options={defaultOptions} height={92} width={82} />
        </div>
      );
    } else {
      if (noSearchResults) {
        return (
          <div className={styles.emptyStateContainer}>
            <div className={styles.emptyMessageContainer}>
              No documents found
            </div>
          </div>
        );
      } else {
        return (
          <img className={styles.inReviewEmptyState} src={inReviewEmptyState} />
        );
      }
    }
  };

  const favouriteStyle = (document) => {
    if (showSpinnerForDocumentId === document.id) {
      return styles.loading;
    } else {
      return styles.favourite;
    }
  };

  const favouriteStatus = (document) => {
    if (showSpinnerForDocumentId === document.id) {
      return loading;
    } else {
      if (favouriteStatuses[document.id] === "favourited") {
        return favouriteIconActive;
      } else {
        if (document.favourite_id && !favouriteStatuses[document.id]) {
          return favouriteIconActive;
        } else {
          return favouriteIcon;
        }
      }
    }
  };

  const toggleFavourite = (document) => {
    if (
      (document.favourite_id && !favouriteStatuses[document.id]) ||
      (document.favourite_id && favouriteStatuses[document.id] === "favourited")
    ) {
      setShowSpinnerForDocumentId(document.id);
      httpClient
        .delete(`/favourites/${document.favourite_id}.json`)
        .then((response) => {
          let favouritesCopy = { ...favouriteStatuses };
          favouritesCopy[document.id] = "unfavourited";
          setFavouriteStatuses(favouritesCopy);
          setShowSpinnerForDocumentId(null);
        });
    } else if (
      (!document.favourite_id && !favouriteStatuses[document.id]) ||
      (!document.favourite_id &&
        favouriteStatuses[document.id] === "unfavourited")
    ) {
      const favourite = {
        document_id: document.id,
        user_id: security.getUserId(),
      };

      setShowSpinnerForDocumentId(document.id);

      httpClient.post("/favourites.json", favourite).then((response) => {
        let favouritesCopy = { ...favouriteStatuses };
        favouritesCopy[document.id] = "favourited";
        setFavouriteStatuses(favouritesCopy);
        setShowSpinnerForDocumentId(null);
      });
    }
  };

  const renderOwnerInitials = (document) => {
    let avatarStyle;
    if (document.uploaded_by_user_id === security.getUserId()) {
      avatarStyle = styles.ownerInitials;
    } else {
      avatarStyle = styles.notOwnerInitials;
    }
    return (
      <div
        className={
          document.document_has_notifications
            ? styles.notificationAvatarContainer
            : styles.avatarContainer
        }
      >
        {document.document_has_notifications && (
          <div className={styles.newContainer} data-title={`New`}>
            <img
              className={styles.notificationIndicator}
              src={notificationIndicator}
            />
          </div>
        )}
        <div
          className={styles.favouriteContainer}
          data-title={`Favourite`}
          onClick={() => {
            toggleFavourite(document);
          }}
        >
          <img
            className={favouriteStyle(document)}
            src={favouriteStatus(document)}
          />
        </div>
        <div
          className={avatarStyle}
          data-title={
            document.uploaded_by_user_id === security.getUserId()
              ? "You are the owner"
              : `${document.document_owner_name}`
          }
        >
          {document.document_owner_initials}
        </div>
      </div>
    );
  };

  const statusStyle = (status) => {
    if (status === "pending") {
      return styles.waiting;
    } else if (status === "approved") {
      return styles.approved;
    } else if (status === "approve_with_changes") {
      return styles.approveWithChanges;
    } else if (status === "submit_new_version") {
      return styles.rejected;
    } else if (status === "change_requested") {
      return styles.requestingChanges;
    }
  };

  const renderStatus = (rawStatus) => {
    let status = "";

    if (rawStatus === "pending") {
      status = "Waiting";
    } else if (rawStatus === "approved") {
      status = "Approved";
    } else if (rawStatus === "approve_with_changes") {
      status = "Approved with changes";
    } else if (rawStatus === "submit_new_version") {
      status = "Requested new version";
    } else if (rawStatus === "change_requested") {
      status = "Changes requested";
    }

    return status;
  };

  const statusBadgeWidth = (status) => {
    let statusStyle = "";

    if (status === "pending") {
      statusStyle = styles.pending;
    } else if (status === "approved") {
      statusStyle = styles.approved;
    } else if (status === "approve_with_changes") {
      statusStyle = styles.approveWithChanges;
    } else if (status === "submit_new_version") {
      statusStyle = styles.submitNewVersion;
    } else if (status === "change_requested") {
      statusStyle = styles.changeRequested;
    }
    return statusStyle;
  };

  const renderApprovers = (approvers) => {
    let nonObserverReviewers = approvers.filter(
      (approver) => !approver.observer
    );
    if (nonObserverReviewers.length === 0) {
      return <div>There are no reviewers for this content.</div>;
    } else {
      return (
        <div className={styles.approverCardContainer}>
          {nonObserverReviewers.map((approver) => {
            return (
              <div className={styles.approverCard}>
                <div className={styles.initialContainer}>
                  {approver.approver_initials}
                </div>
                <div className={styles.cardRightContainer}>
                  <h3 className={styles.approverName}>
                    {approver.approver_name}
                  </h3>
                  <div className={statusBadgeWidth(approver.status)}>
                    <p className={statusStyle(approver.status)}>
                      {renderStatus(approver.status)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const hoverStateStyle = (index) => {
    let hoverState = {};
    if (index > 5) {
      hoverState = styles.hoverStateContainerBottom;
    } else {
      hoverState = styles.hoverStateContainer;
    }
    return hoverState;
  };

  const renderCell = (cell, columnIndex, documentIndex) => {
    if (columnIndex === 1) {
      if (cell) {
        return moment(cell).format("MMM DD, YYYY");
      } else {
        return "-";
      }
    } else if (columnIndex === 2) {
      return (
        <div
          onMouseEnter={() => onProgressBarHover(documentIndex)}
          onMouseLeave={() => onProgressBarLeave(documentIndex)}
          className={styles.hoverContainer}
        >
          {cell}
          <div
            ref={elRefs[documentIndex]}
            className={hoverStateStyle(documentIndex)}
          >
            {renderApprovers(
              sortedDocs.length > 0
                ? sortedDocs[documentIndex].approvals
                : documentsInReview[documentIndex].approvals
            )}
          </div>
          <div className={styles.progressContainer}>
            <div className={styles.tail} ref={tooltipRefs[documentIndex]}></div>
          </div>
        </div>
      );
    } else {
      if (cell) {
        return cell;
      } else {
        return "-";
      }
    }
  };

  const renderTable = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.tableOneContainer}>
          <table className={styles.titleTableContainer}>
            <colgroup>
              <col span="1" className={styles.col} />
            </colgroup>
            <thead className={styles.header}>
              <tr>
                <th className={styles.unsortableColumnName}>
                  <div className={styles.container}>
                    <div className={styles.avatarColumn}>{"owner"}</div>
                    <div className={styles.icon}></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              {sortedDocs.length > 0
                ? sortedDocs.map((doc, index) => (
                    <tr key={index} name={doc.id}>
                      <td key={index}>{renderOwnerInitials(doc)}</td>
                    </tr>
                  ))
                : documentsInReview.map((doc, index) => (
                    <tr key={index} name={doc.id}>
                      <td key={index}>{renderOwnerInitials(doc)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className={styles.tableSubContainer}>
          <table className={styles.tableContainer}>
            <colgroup className={styles.colgroup}>
              {tableProperties.map((column, index) =>
                index === 0 ? (
                  <col key={index} span="1" className={styles.colLarge} />
                ) : (
                  <col key={index} span="1" className={styles.col} />
                )
              )}
            </colgroup>
            <thead className={styles.header}>
              <tr>
                {tableProperties.map((column, index) => {
                  if (column !== "status" && column !== "open document") {
                    return (
                      <th className={styles.columnName} key={index}>
                        <div
                          className={styles.titleColumn}
                          onClick={() => sort(column, index)}
                        >
                          <div
                            className={`${styles.text} ${
                              sortingColumnIndex === index && styles.textBold
                            }`}
                          >
                            {column}
                          </div>
                          <div className={styles.icon}>
                            <img
                              className={
                                sortingColumnIndex === index &&
                                sortOrder === "desc"
                                  ? styles.show
                                  : ""
                              }
                              src={ArrowDownIcon}
                            />
                            <img
                              className={`${
                                sortingColumnIndex === index
                                  ? styles.arrowUpShow
                                  : ""
                              } ${
                                sortingColumnIndex === index &&
                                sortOrder === "asc"
                                  ? styles.show
                                  : ""
                              }`}
                              src={ArrowUpIcon}
                            />
                          </div>
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            {documentsInReview.length !== 0 && (
              <tbody className={styles.body}>
                {sortedDocs.length > 0
                  ? sortedDocs.map((link, index) => (
                      <tr key={index} className={styles.tableRow}>
                        {rows[link.id].map((cell, columnIndex) => {
                          return (
                            <td
                              className={styles.previewLink}
                              onClick={() => showContent(link, columnIndex)}
                              key={columnIndex}
                            >
                              {renderCell(cell, columnIndex, index)}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : documentsInReview.map((link, index) => (
                      <tr key={index} className={styles.tableRow}>
                        {rows[link.id].map((cell, columnIndex) => {
                          return (
                            <td
                              className={styles.previewLink}
                              onClick={() => showContent(link, columnIndex)}
                              key={columnIndex}
                            >
                              {renderCell(cell, columnIndex, index)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  };

  const renderInReviewPanel = () => {
    return (
      <div
        className={
          documentsInReview.length === 0
            ? styles.inReviewEmptyStateContainer
            : ""
        }
      >
        {documentsInReview.length === 0 ? renderEmptyState() : renderTable()}
      </div>
    );
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <div>{renderInReviewPanel()}</div>;
};
