import React, { useState, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import httpClient from "../../lib/HttpClient";
import axiosClient from "../../lib/axios-client";
import download from "downloadjs";
import security from "../../services/Security";
import axios from "../../lib/axios-client";
import { Container, Row, Col } from "react-grid-system";
import VideoApp from "../Video/VideoApp";
import {
  apiUrl,
  pdftronServerUrl,
  loadDocumentUrl,
} from "../../lib/axios-client";

import DownloadSVG from "../../images/icons/svg/share-link-download-mobile.svg";
import OopsImage from "../../images/icons/svg/oops-image.svg";
import styles from "./ShareLinkViewer.module.scss";

const ShareLinkViewer = (props) => {
  const viewerRef = useRef(null);
  const hashUrl = props.match.params.hash_url;

  const [video, setVideo] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [error, setError] = useState(false);
  const [duration, setDuration, durationRef] = useStateRef(0);
  const [intervalId, setIntervalId] = useState(null);
  const [sessionId] = useState(
    [...Array(32)].map(() => Math.random().toString(36)[2]).join("")
  );
  const [instance, setInstance] = useState(null);
  const [documentDynamicFields, setDocumentDynamicFields] = useState([]);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === "hidden") {
      await httpClient.put("/share_links/session/duration", {
        hash_url: hashUrl,
        session_id: sessionId,
        duration: durationRef.current,
      });
      setDuration(0);
      clearInterval(intervalId);
    } else {
      const intervalId = setInterval(() => {
        setDuration((value) => value++);
      }, 1000);
      setIntervalId(intervalId);
    }
  };

  const beforeunload = async () => {
    httpClient.put("/share_links/session/duration", {
      hash_url: hashUrl,
      session_id: sessionId,
      duration: durationRef.current,
    });
  };

  let documentContainer = null;

  const downloadDoc = (documentDetails) => {
    const url = `/share_links/download`;
    const title = `${documentDetails.title}${documentDetails.file_extension}`;
    const type = documentDetails.mime_type;
    const token = security.getToken();

    axiosClient({
      method: "post",
      url: url,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        hash_url: hashUrl,
        session_id: sessionId,
      },
    }).then(function (response) {
      if (documentDetails.generated) {
        let pdftron = window.WebViewer.getInstance();
        pdftron.downloadPdf({
          filename: `${documentDetails.title}.pdf`,
        });
      } else {
        download(response.data, title, type);
      }
    });
  };

  useEffect(() => {
    httpClient
      .get(`${loadDocumentUrl()}/share_links/view/${hashUrl}`)
      .then((res) => {
        if (res.data.video_document) {
          setVideo(res.data.video_document);

          httpClient.post(`/share_links/session/create.json`, {
            hash_url: hashUrl,
            session_id: sessionId,
          });

          const beforeunload = async () => {
            httpClient.put("/share_links/session/duration", {
              hash_url: hashUrl,
              session_id: sessionId,
              duration: durationRef.current,
            });
          };

          const counterInterval = setInterval(() => {
            setDuration((prevDuration) => prevDuration + 1);
          }, 1000);

          setIntervalId(counterInterval);

          document.addEventListener("visibilitychange", handleVisibilityChange);

          document.addEventListener("beforeunload", beforeunload);

          return () => {
            window.removeEventListener("beforeunload", beforeunload);
          };
        } else {
          const loadDocument = async () => {
            try {
              let webViewerOptions = {
                path: "/vendor/pdf/lib",
                fullAPI: false,
                disabledElements: [
                  "notesPanelResizeBar",
                  "filterAnnotationButton",
                  "notesOrderDropdown",
                  "thumbDelete",
                  "thumbRotateClockwise",
                  "leftPanelResizeBar",
                  "searchPanelResizeBar",
                  "fullscreenButton",
                  "printButton",
                  "themeChangeButton",
                  "deletePage",
                  "insertPageBelow",
                  "insertPageAbove",
                  "pageInsertionHeader",
                  "panToolButton",
                  "selectToolButton",
                  "toggleNotesButton",
                  "menuButton",
                  "annotationCommentButton",
                  "annotationStyleEditButton",
                  "linkButton",
                  "annotationPopup",
                  "outlinesPanelButton",
                  "outlinesPanel",
                  "contextMenuPopup",
                  "notePopup",
                  "thumbnailControl",
                  "documentControl",
                  "annotationContentOverlay",
                  "layersPanelButton",
                  "highlightToolButton",
                  "highlightToolButton2",
                  "highlightToolButton3",
                  "highlightToolButton4",
                  "underlineToolButton",
                  "squigglyToolButton",
                  "strikeoutToolButton",
                  "ellipseToolButton",
                  "lineToolButton",
                  "arrowToolButton",
                  "polylineToolButton",
                  "polygonToolButton",
                  "cloudToolButton",
                  "calloutToolButton",
                  "stampToolButton",
                  "fileAttachmentToolButton",
                  "cropToolButton",
                  "freeHandToolButton",
                  "freeHandToolButton2",
                  "freeHandToolButton3",
                  "freeHandToolButton4",
                  "rectangleToolButton",
                  "ribbons",
                  "signaturePanelButton",
                  "copyTextButton",
                ],
                css: "/vendor/pdf/lib/ui/ShareLinkViewerStyle.css",
                isAdminUser: true,
                licenseKey: `${process.env.REACT_APP_PDFTRON_LICENSE_KEY}`,
              };

              // Stopping this for now due to CACHE issues on new document version uploads
              // Must implement cachekey
              //if (process.env.REACT_APP_WEBVIEWER_SERVER === "true") {
              //  webViewerOptions["pdftronServer"] = pdftronServerUrl;
              //}

              const instance = await WebViewer(
                webViewerOptions,
                viewerRef.current
              );

              const UIEvents = instance.UI.Events;

              instance.UI.addEventListener(UIEvents.LOAD_ERROR, () => {
                setError(true);
              });

              instance.updateElement("viewControlsButton", {
                img: "/assets/svg/default-viewcontrols.svg",
              });

              instance.updateElement("leftPanelButton", {
                img: "/assets/svg/default-leftsidepanel.svg",
              });

              instance.updateElement("zoomOutButton", {
                img: "/assets/svg/default-minus.svg",
              });

              instance.updateElement("zoomInButton", {
                img: "/assets/svg/default-plus.svg",
              });

              instance.updateElement("zoomOverlay", {
                img: "/assets/svg/chevron.svg",
              });

              instance.updateElement("panToolButton", {
                img: "/assets/svg/default-pan.svg",
              });

              instance.updateElement("searchButton", {
                img: "/assets/svg/default-magnifying-glass.svg",
              });

              instance.disableTools([
                "TextSelectTool",
                "AnnotationComment",
                "AnnotationCreateTextSquiggly",
                "AnnotationCreateFileAttachment",
                "AnnotationCreateAreaMeasurement",
                "AnnotationCreateArrow",
                "AnnotationCreateCallout",
                "AnnotationCreateCountMeasurement",
                "CropPage",
                "AnnotationCreateDistanceMeasurement",
                "AnnotationCreateEllipse",
                "AnnotationCreateEllipseMeasurement",
                "AnnotationEraserTool",
                "AnnotationCreateFreeHand",
                "AnnotationCreateFreeHand2",
                "AnnotationCreateFreeHand3",
                "AnnotationCreateFreeHand4",
                "AnnotationCreateFreeText",
                "AnnotationCreateLine",
                "AnnotationCreatePerimeterMeasurement",
                "AnnotationCreatePolygon",
                "AnnotationCreatePolygonCloud",
                "AnnotationCreatePolyline",
                "AnnotationCreateRectangle",
                "AnnotationCreateRectangularAreaMeasurement",
                "AnnotationCreateRedaction",
                "AnnotationCreateRubberStamp",
                "AnnotationCreateStamp",
                "AnnotationCreateSticky",
                "AnnotationCreateTextStrikeout",
                "TextSelect",
                "AnnotationCreateTextUnderline",
                "AnnotationCreateTextHighlight2",
                "AnnotationCreateTextHighlight3",
                "AnnotationCreateTextHighlight4",
                "AnnotationCreateTextHighlight",
              ]);

              instance.UI.loadDocument(
                `${loadDocumentUrl()}/share_links/view/${hashUrl}`,
                {
                  onError: () => {
                    console.log("error");
                    setError(true);
                  },
                }
              );

              const response = await httpClient.post(
                `/share_links/session/create.json`,
                {
                  hash_url: hashUrl,
                  session_id: sessionId,
                }
              );

              httpClient
                .get(
                  `/documents/${response.data.id}/share_link/document_dynamic_fields/${hashUrl}.json`
                )
                .then((response) => {
                  setDocumentDynamicFields(response.data);
                  setInstance(instance);
                });

              const downloadButtonDesktop = {
                type: "customElement",
                render: () => (
                  <button
                    className="download-button__desktop"
                    onClick={() => downloadDoc(response.data)}
                  >
                    Download
                  </button>
                ),
                hidden: ["mobile"],
              };

              const downloadButtonMobile = {
                type: "customElement",
                render: () => (
                  <div
                    className="download-button__mobile"
                    onClick={() => downloadDoc(response.data)}
                  >
                    <img src={DownloadSVG} />
                  </div>
                ),
                hidden: ["desktop", "tablet"],
              };

              instance.setHeaderItems((header) => {
                const items = header.getItems();
                items.splice(4, 1);
                header.update(items);
                header.get("searchButton").insertBefore(downloadButtonDesktop);
                header.get("searchButton").insertBefore(downloadButtonMobile);
              });

              setDocumentDetails(response.data);

              const counterInterval = setInterval(() => {
                setDuration((prevDuration) => prevDuration + 1);
              }, 1000);

              setIntervalId(counterInterval);

              document.addEventListener("beforeunload", beforeunload);

              document.addEventListener(
                "visibilitychange",
                handleVisibilityChange
              );
            } catch (err) {
              console.log("error");
              setError(true);
            }
          };
          loadDocument();

          return () => {
            window.removeEventListener("beforeunload", beforeunload);
          };
        }
      });
  }, []);

  instance &&
    instance.docViewer.on("documentLoaded", () => {
      let options = {};

      documentDynamicFields.map((field) => {
        if (field.field_value && field.field_value !== "null") {
          options[`${field.field_name}`] = `${field.field_value}`;
        } else {
          options[`${field.field_name}`] = `{{${field.field_name}}}`;
        }
      });

      documentDynamicFields.map((field) => {
        if (
          field.has_attached_image ||
          field.has_attached_structured_content_image
        ) {
          options[field.field_name] = {
            image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
              field.id
            }`,
            width: field.image_width ? parseInt(field.image_width) : 64,
            height: field.image_height ? parseInt(field.image_height) : 64,
          };
        }
      });

      instance.docViewer.getDocument().applyTemplateValues(options);
    });

  const downloadVideoFile = () => {
    const url = `/share_links/download`;
    const token = security.getToken();

    axiosClient({
      method: "post",
      url: url,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        hash_url: hashUrl,
        session_id: sessionId,
        video_download: true,
      },
    });

    axios({
      method: "get",
      url: video.video_download_url,
      responseType: "arraybuffer",
    }).then(function (response) {
      download(response.data, video.video_file_name);
    });
  };

  return (
    <div className={styles.container} id="document_container">
      {!error && (
        <React.Fragment>
          <div id="usersScreenHeader"></div>
          <div className={styles.headerContainer}>
            <div className={styles.header} id="document_view_header">
              {video && video.document_title}
            </div>
            {video && (
              <button
                className={styles.downloadButtonDesktop}
                onClick={() => downloadVideoFile()}
              >
                Download
              </button>
            )}
            {video && (
              <div
                className={styles.downloadButtonMobile}
                onClick={() => downloadVideoFile()}
              >
                <img src={DownloadSVG} />
              </div>
            )}
          </div>
          {video && (
            <React.Fragment>
              <Col
                sm={9}
                className={styles.documentContainer}
                ref={(element) => {
                  documentContainer = element;
                }}
                id="document_container"
              >
                <VideoApp doc={video} documentContainer={documentContainer} />
              </Col>{" "}
            </React.Fragment>
          )}
          {!video && (
            <React.Fragment>
              <div className={styles.header} id="document_view_header">
                {documentDetails ? `${documentDetails.title}` : ""}
              </div>
              <div className={styles.webViewerContainer} ref={viewerRef}></div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {error && (
        <div className={styles.errorState}>
          <div className={styles.text}>
            <div className={styles.header}>oops!</div>
            <div className={styles.text}>
              Looks like this link no longer exists
            </div>
          </div>
          <div className={styles.image}>
            <img src={OopsImage} />
          </div>
        </div>
      )}
    </div>
  );
};

const useStateRef = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};

export default ShareLinkViewer;
