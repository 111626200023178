import React, { useEffect, useRef, useState } from "react";
import Modal from "../Common/Modal";
import httpClient from "../../lib/HttpClient";
import closeBlack from "../../images/icons/svg/close-black.svg";
import history from "../../history";

import WebViewer from "@pdftron/webviewer";
import security from "../../services/Security";
import { loadDocumentUrl, pdftronServerUrl } from "../../lib/axios-client";
import VideoApp from "../Video/VideoApp";
import { Col } from "react-grid-system";
import Lottie from "react-lottie";
import loadingAnimation from "../../images/lotties/loading.json";
import styles from "./DashboardPreviewModal.module.scss";

export default (props) => {
  const {
    showDashboardPreviewModal,
    setShowDashboardPreviewModal,
    previewDoc,
    conversationPreview,
    setConversationPreview,
    documentsInReview,
    setDocumentsInReview,
    selectedComment,
    setSelectedComment,
  } = props;

  const viewerRef = useRef(null);

  const [video, setVideo] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  let documentContainer = null;

  useEffect(() => {
    if (!previewDoc.video_id) {
      const loadDocument = async () => {
        try {
          let webViewerOptions = {
            path: "/vendor/pdf/lib",
            fullAPI: false,
            disabledElements: [
              "notesPanelResizeBar",
              "filterAnnotationButton",
              "notesOrderDropdown",
              "thumbDelete",
              "thumbRotateClockwise",
              "leftPanelResizeBar",
              "searchPanelResizeBar",
              "fullscreenButton",
              "printButton",
              "themeChangeButton",
              "deletePage",
              "insertPageBelow",
              "insertPageAbove",
              "pageInsertionHeader",
              "panToolButton",
              "selectToolButton",
              "toggleNotesButton",
              "menuButton",
              "annotationCommentButton",
              "annotationStyleEditButton",
              "linkButton",
              "annotationPopup",
              "outlinesPanelButton",
              "outlinesPanel",
              "contextMenuPopup",
              "notePopup",
              "thumbnailControl",
              "documentControl",
              "annotationContentOverlay",
              "layersPanelButton",
              "highlightToolButton",
              "highlightToolButton2",
              "highlightToolButton3",
              "highlightToolButton4",
              "underlineToolButton",
              "squigglyToolButton",
              "strikeoutToolButton",
              "ellipseToolButton",
              "lineToolButton",
              "arrowToolButton",
              "polylineToolButton",
              "polygonToolButton",
              "cloudToolButton",
              "calloutToolButton",
              "stampToolButton",
              "fileAttachmentToolButton",
              "cropToolButton",
              "freeHandToolButton",
              "freeHandToolButton2",
              "freeHandToolButton3",
              "freeHandToolButton4",
              "rectangleToolButton",
              "ribbons",
              "signaturePanelButton",
              "copyTextButton",
            ],
            css: "/vendor/pdf/lib/ui/ShareLinkViewerStyle.css",
            isAdminUser: true,
            licenseKey: `${process.env.REACT_APP_PDFTRON_LICENSE_KEY}`,
          };

          //if (process.env.REACT_APP_WEBVIEWER_SERVER === "true") {
          //  webViewerOptions["pdftronServer"] = pdftronServerUrl;
          //}

          let instance = await WebViewer(webViewerOptions, viewerRef.current);

          const { docViewer, annotManager } = instance;

          const loadCommentAnnotations = () => {
            if (conversationPreview && conversationPreview.xfdf_highlight_id) {
              annotManager
                .importAnnotCommand(conversationPreview.xfdf_highlight_string)
                .then((annotationList) => {
                  annotationList.forEach((annot) => {
                    if (conversationPreview.xfdf_highlight_id === annot.Id) {
                      if (annot.Subject === "Stamp") {
                        annot.NoResize = true;
                      }
                      annotManager.redrawAnnotation(annot);
                    } else {
                      annotManager.hideAnnotation(annot);
                    }
                  });
                });
              docViewer.setCurrentPage(conversationPreview.page.page_number);
            } else {
              let documentsInReviewCopy = [...documentsInReview];
              let previewDocCopy = { ...previewDoc };
              previewDocCopy.document_has_notifications = false;
              documentsInReviewCopy.splice(
                documentsInReview.indexOf(previewDoc),
                1,
                previewDocCopy
              );
              setDocumentsInReview(documentsInReviewCopy);
            }
          };

          docViewer.on("documentLoaded", () => {
            if (process.env.REACT_APP_WEBVIEWER_SERVER === "true") {
              setShowLoader(false);
              loadCommentAnnotations();
            } else {
              docViewer
                .getDocument()
                .documentCompletePromise()
                .then(async () => {
                  setShowLoader(false);
                  loadCommentAnnotations();
                });
            }
          });

          const UIEvents = instance.UI.Events;

          instance.updateElement("viewControlsButton", {
            img: "/assets/svg/default-viewcontrols.svg",
          });

          instance.updateElement("leftPanelButton", {
            img: "/assets/svg/default-leftsidepanel.svg",
          });

          instance.updateElement("zoomOutButton", {
            img: "/assets/svg/default-minus.svg",
          });

          instance.updateElement("zoomInButton", {
            img: "/assets/svg/default-plus.svg",
          });

          instance.updateElement("zoomOverlay", {
            img: "/assets/svg/chevron.svg",
          });

          instance.updateElement("panToolButton", {
            img: "/assets/svg/default-pan.svg",
          });

          instance.updateElement("searchButton", {
            img: "/assets/svg/default-magnifying-glass.svg",
          });

          instance.disableTools([
            "TextSelectTool",
            "AnnotationComment",
            "AnnotationCreateTextSquiggly",
            "AnnotationCreateFileAttachment",
            "AnnotationCreateAreaMeasurement",
            "AnnotationCreateArrow",
            "AnnotationCreateCallout",
            "AnnotationCreateCountMeasurement",
            "CropPage",
            "AnnotationCreateDistanceMeasurement",
            "AnnotationCreateEllipse",
            "AnnotationCreateEllipseMeasurement",
            "AnnotationEraserTool",
            "AnnotationCreateFreeHand",
            "AnnotationCreateFreeHand2",
            "AnnotationCreateFreeHand3",
            "AnnotationCreateFreeHand4",
            "AnnotationCreateFreeText",
            "AnnotationCreateLine",
            "AnnotationCreatePerimeterMeasurement",
            "AnnotationCreatePolygon",
            "AnnotationCreatePolygonCloud",
            "AnnotationCreatePolyline",
            "AnnotationCreateRectangle",
            "AnnotationCreateRectangularAreaMeasurement",
            "AnnotationCreateRedaction",
            "AnnotationCreateRubberStamp",
            "AnnotationCreateStamp",
            "AnnotationCreateSticky",
            "AnnotationCreateTextStrikeout",
            "TextSelect",
            "AnnotationCreateTextUnderline",
            "AnnotationCreateTextHighlight2",
            "AnnotationCreateTextHighlight3",
            "AnnotationCreateTextHighlight4",
            "AnnotationCreateTextHighlight",
          ]);

          const token = security.getToken();

          instance.UI.loadDocument(
            `${loadDocumentUrl()}/documents/${previewDoc.id}/${
              selectedComment ? selectedComment.id : "no_comment"
            }/file${previewDoc.file_extension}`,
            {
              customHeaders: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (err) {
          console.log("error", err);
        }
      };
      loadDocument();
    } else {
      httpClient
        .get(`/documents/${previewDoc.id}/preview_video.json`)
        .then((res) => {
          setShowLoader(false);
          setVideo(res.data.video_document);
          if (!conversationPreview) {
            let documentsInReviewCopy = [...documentsInReview];
            let previewDocCopy = { ...previewDoc };
            previewDocCopy.document_has_notifications = false;
            documentsInReviewCopy.splice(
              documentsInReview.indexOf(previewDoc),
              1,
              previewDocCopy
            );
            setDocumentsInReview(documentsInReviewCopy);
          }
        });
    }
  }, []);

  const hideModal = () => {
    setShowDashboardPreviewModal(false);
    setConversationPreview(null);
    setSelectedComment(null);
  };

  const openFile = async () => {
    let commentUrl;

    if (selectedComment) {
      if (selectedComment.conversation.xfdf_highlight_id) {
        commentUrl = `/drafts/${previewDoc.id}?xfdfId=${selectedComment.conversation.xfdf_highlight_id}`;
      } else {
        commentUrl = `/drafts/${previewDoc.id}?xfdfId=${previewDoc.id}-general`;
      }
    } else {
      commentUrl = `/drafts/${previewDoc.id}`;
    }

    await setSelectedComment(null);
    history.push(commentUrl);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="document_container" className={styles.modalContainer}>
      <div
        id="document_view_header"
        className={styles.activityLogHeaderContainer}
      >
        <h1 className={styles.activityLogHeading}>{previewDoc.title}</h1>
        <div
          className={video ? styles.rightContainerVideo : styles.rightContainer}
        >
          <a
            className={video ? styles.actionButtonVideo : styles.actionButton}
            onClick={() => openFile()}
          >
            Open file
          </a>
          <img
            className={styles.closeButton}
            onClick={() => hideModal()}
            src={closeBlack}
          />
        </div>
      </div>
      {video && (
        <div
          className={
            video ? styles.videoPreviewContainer : styles.webViewerContainer
          }
        >
          {showLoader && (
            <div className={styles.loaderContainer}>
              <Lottie options={defaultOptions} height={92} width={82} />
            </div>
          )}
          <React.Fragment>
            {video && (
              <React.Fragment>
                <Col
                  sm={9}
                  ref={(element) => {
                    documentContainer = element;
                  }}
                >
                  <VideoApp doc={video} documentContainer={documentContainer} />
                </Col>{" "}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      )}
      {!video && (
        <div className={styles.webViewerContainer} ref={viewerRef}>
          {showLoader && (
            <div className={styles.loaderContainer}>
              <Lottie options={defaultOptions} height={92} width={82} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
