import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardConversationPanel.module.scss";
import httpClient from "../../lib/HttpClient";
import moment from "moment";
import Checkmark from "../Common/Checkmark";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import "./dashboardMentionsStyles.css";
import { connect } from "react-redux";
import resetSearchCommentResults from "../../actions/document/resetSearchCommentResults";

/* Papercurve Components */
import Comment from "./Comment";

import commentService from "../../services/Comments";
import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import "draft-js/dist/Draft.css";

import sendIcon from "../../images/icons/16px/send.svg";
import moreSVG from "../../images/icons/svg/more.svg";
import CloseSVG from "../../images/icons/svg/close-black.svg";
import leftarrowSVG from "../../images/icons/svg/left-arrow-black.svg";
import sendButtonSVG from "../../images/icons/svg/send-comment-button-white.svg";
import spinnerSVG from "../../images/icons/svg/loading-white.svg";

/* Variables */

class CommentPanel extends React.Component {
  constructor(props) {
    super(props);

    this.resizeTimeout = null;
    this.myRef = React.createRef();
    this.commentPanelRef = React.createRef();
    this.focusedSuggestionRef = React.createRef();

    let mentions = [];

    if (!this.props.inGuestViewer) {
      this.props.selectedComment.approvers &&
        this.props.selectedComment.approvers.forEach((approver) => {
          if (approver.active) {
            mentions.push({
              id: approver.id,
              name: `${approver.first_name} ${approver.last_name}`,
              title: `${approver.title}`,
              guest: `${approver.guest}`,
            });
          }
        });
      mentions.push({
        id: this.props.owner.id,
        name: `${this.props.owner.first_name} ${this.props.owner.last_name}`,
        title: `${this.props.owner.title}`,
        guest: `${this.props.owner.guest}`,
      });
    }

    this.state = {
      conversation: {},
      commentBody: "",
      currentUserId: security.getUserId(),
      addTimestamp: false,
      currentTimestamp: 0,
      editorState: EditorState.createEmpty(),
      mentions,
      suggestions: mentions,
      sendButtonDisabled: false,
      showSpinner: false,
      suggestedClaims: [],
      showFoundClaim: false,
    };

    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: {
        mention: "dashboardMention",
        mentionSuggestions: "dashboardMentionSuggestions",
        mentionSuggestionsEntry: "dashboardMentionSuggestionsEntry",
        mentionSuggestionsEntryText: "dashboardMentionSuggestionsEntryText",
        mentionSuggestionsEntryFocused:
          "dashboardMentionSuggestionsEntryFocused",
        mentionSuggestionsEntryAvatar: "dashboardMentionSuggestionsEntryAvatar",
      },
    });
    this.setDomEditorRef = (ref) => (this.domEditor = ref);
    this.focus = () => this.domEditor.focus();
  }

  componentWillMount = () => {
    this.setState(
      {
        conversation: this.props.selectedComment.conversation,
      },
      () => {
        const commentPanel = document.getElementById("sidePanelOptions");
        commentPanel.scrollTop = commentPanel.scrollHeight;
      }
    );
    let notification = this.props.notifications.find(
      (n) => n.comment_id === this.props.selectedComment.id
    );

    if (notification) {
      httpClient
        .put(`/notifications/${notification.id}.json`, {
          notification: {
            id: notification.id,
            status: "read",
          },
        })
        .then((res) => {
          httpClient.get("/active_conversations.json").then((response) => {
            this.props.setComments(response.data.user_comments_mentions);
          });
        });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.conversation &&
      this.state.conversation.id &&
      prevState.conversation &&
      !prevState.conversation.id &&
      this.myRef.current
    ) {
      this.myRef.current.scrollIntoView(true);
    }
    if (
      this.props.currentXfdfHighlightId === null &&
      prevProps.currentXfdfHighlightId !== null
    ) {
      this.setState({ conversation: {} });
    }
    if (
      this.props.currentXfdfHighlightId !== prevProps.currentXfdfHighlightId &&
      this.props.currentXfdfHighlightId !== null
    ) {
      let url = !this.props.inGuestViewer
        ? `/conversations/xfdf/${this.props.currentXfdfHighlightId}.json`
        : `/conversations/xfdf/guest/${this.props.currentXfdfHighlightId}/${this.props.hashUrl}.json`;

      httpClient.get(url).then((response) => {
        this.setState({ conversation: response.data });

        this.retrieveUnsavedComment(response.data.id);
      });
    }

    if (this.commentPanelRef.current) {
      this.commentPanelRef.current.scrollTop =
        this.commentPanelRef.current.scrollHeight;
    }
  };

  canDeleteConversation = (conversation) => {
    let canDelete = false;
    if (security.getUserRole() == "admin") {
      canDelete = true;
    } else if (this.props.inGuestViewer) {
      if (conversation.conversation_type === "strikethrough") {
        if (conversation.started_by_user.id === this.props.guestUserId) {
          canDelete = true;
        }
      } else if (
        conversation.conversation_type !== "strikethrough" &&
        conversation.comments &&
        conversation.comments.length > 0 &&
        conversation.comments[0].conversation.started_by_user_id ===
          this.props.guestUserId
      ) {
        canDelete = true;
      }
    } else {
      if (conversation.conversation_type === "strikethrough") {
        if (conversation.started_by_user.id === security.getUserId()) {
          canDelete = true;
        }
      } else if (
        conversation.conversation_type !== "strikethrough" &&
        conversation.comments &&
        conversation.comments.length > 0 &&
        conversation.comments[0].conversation.started_by_user_id ==
          security.getUserId()
      ) {
        canDelete = true;
      }
    }

    return canDelete;
  };

  retrieveConversation = (conversationId) => {
    if (!conversationId) {
      conversationId = this.state.conversation.id;
    }

    let url = !this.props.inGuestViewer
      ? `/conversations/${conversationId}.json`
      : `guest/conversations/${conversationId}/${this.props.hashUrl}.json`;

    httpClient.get(url).then((response) => {
      this.setState({ conversation: response.data });
    });
  };

  retrieveUnsavedComment = (id = null) => {
    const conversationComment = localStorage.getItem(`conversationID-${id}`);

    const documentComment = localStorage.getItem(
      `conversationDocumentID-${this.props.docId}`
    );
    if (id && conversationComment) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(conversationComment)
      );

      this.setState({ editorState });
    } else if (documentComment) {
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(documentComment)
      );

      this.setState({ editorState });
    }
  };

  addComment = () => {
    this.currentAddComment();
  };

  toggleConversationStatus = () => {
    let status = "resolved";
    if (this.state.conversation.status === "resolved") {
      status = "unresolved";
    }

    let url = `/conversations/${
      this.state.conversation.xfdf_highlight_id
        ? this.state.conversation.xfdf_highlight_id
        : this.state.conversation.block_identifier
    }.json`;

    httpClient
      .put(url, {
        hash_url: this.props.hashUrl,
        conversation: {
          status,
        },
      })
      .then((res) => {
        httpClient.get("/active_conversations.json").then((response) => {
          this.props.setComments(response.data.user_comments_mentions);
          this.setState({ conversation: res.data });
        });
      });
  };

  currentAddComment = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const { docId, pageNumber } = this.props;

    const contentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );

    let mentions = Object.values(contentState.entityMap)
      .filter((v) => v.type === "mention")
      .map((v) => v.data.mention);

    let newComment = this.state.editorState.getCurrentContent().getPlainText();

    mentions.map((mention) => {
      newComment = newComment.replace(mention.name, `{{${mention.name}}}`);
    });

    mentions = mentions.filter(
      (mention, index, mentions) =>
        mentions.findIndex((i) => i.id === mention.id) === index
    );

    const conversation = this.props.selectedComment.conversation;

    const commentData = {
      hash_url: this.props.hashUrl,
      conversation: {
        document_id: parseInt(conversation.document.id),
        page_number: conversation.block_identifier
          ? 1
          : parseInt(conversation.page.page_number),
        xfdf_highlight_id: conversation.block_identifier
          ? null
          : conversation.xfdf_highlight_id,
        xfdf_highlight_string: conversation.block_identifier
          ? null
          : conversation.currentXfdfHighlightString,
        block_identifier: conversation.block_identifier
          ? conversation.block_identifier
          : null,
      },
      comment: {
        body: newComment,
      },
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    if (this.props.is_video && this.props.inGuestViewer) {
      commentData.conversation.block_identifier =
        this.props.conversationBlockId;
    }

    if (this.props.is_video && this.state.addTimestamp) {
      commentData.comment.video_timestamp = this.state.currentTimestamp;
    }

    this.setState({ sendButtonDisabled: true });

    setTimeout(() => {
      if (that.state.sendButtonDisabled) {
        that.setState({
          showSpinner: true,
        });
      }
    }, 400);

    let url = this.props.inGuestViewer
      ? "/comments/guest.json"
      : "/comments.json";
    httpClient.post(url, commentData).then((convoResponse) => {
      httpClient.get("/active_conversations.json").then((response) => {
        this.props.setComments(response.data.user_comments_mentions);
        this.props.selectedComment.conversation.comments.push(
          convoResponse.data
        );
        this.setState(
          {
            editorState: EditorState.createEmpty(),
            sendButtonDisabled: false,
            showSpinner: false,
          },
          () => {
            const commentPanel = document.getElementById("sidePanelOptions");
            commentPanel.scrollTop = commentPanel.scrollHeight;
          }
        );
      });
    });
  };

  renderComments = () => {
    let commentFound = false;
    return this.state.conversation.comments.map((i, j) => {
      let commentedAtTime = i.commented_at;
      const twelveHours = 60 * 60 * 1000 * 12;
      let canDelete =
        (!this.props.approved &&
          (security.getUserRole() == "admin" ||
            (i.user.id == security.getUserId() &&
              Date.now() - new Date(commentedAtTime) < twelveHours))) ||
        i.user.id === this.props.guestUserId;
      let searchedCommentIdentified =
        this.props.searchResultLibrary &&
        this.props.searchResultLibrary.searchResults.length > 0 &&
        this.props.searchResultLibrary.searchResults.find(
          (comment) => comment.id === i.id
        );
      if (
        this.props.selectedCommentId &&
        this.props.selectedCommentId === i.id
      ) {
        commentFound = true;
        return (
          <div
            key={i.id}
            ref={searchedCommentIdentified ? this.myRef : null}
            className={styles.comment}
          >
            <Comment
              comment={i}
              currentUserId={
                this.props.inGuestViewer
                  ? this.props.guestUserId
                  : this.state.currentUserId
              }
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
            />
          </div>
        );
      } else {
        if (
          this.props.currentXfdfHighlightId &&
          this.props.selectedCommentId &&
          j === this.state.conversation.comments.length - 1 &&
          !commentFound
        ) {
          notificationService.addNotification(
            "Comment removed.",
            "Comment no longer exists.",
            "danger"
          );
        }
        return (
          <div
            key={i.id}
            ref={searchedCommentIdentified ? this.myRef : null}
            className={styles.comment}
          >
            <Comment
              comment={i}
              currentUserId={
                this.props.inGuestViewer
                  ? this.props.guestUserId
                  : this.state.currentUserId
              }
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
            />
          </div>
        );
      }
    });
  };

  onDeleteComment = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      let url = this.props.inGuestViewer
        ? `/comments/guest/${id}/${this.props.hashUrl}.json`
        : `/comments/${id}.json`;
      httpClient.delete(url).then((response) => {
        httpClient
          .get("/active_conversations.json")
          .then((activeConvoResponse) => {
            this.props.setComments(
              activeConvoResponse.data.user_comments_mentions
            );
            let conversation = { ...this.props.selectedComment.conversation };
            let deletedCommentIndex =
              this.props.selectedComment.conversation.comments.indexOf(
                this.props.selectedComment.conversation.comments.find(
                  (c) => c.id === id
                )
              );
            conversation.comments.splice(deletedCommentIndex, 1);
            this.setState({ conversation: conversation });
          });

        notificationService.addNotification(
          "Comment removed.",
          "Comment has been deleted.",
          "danger"
        );
      });
    }
  };

  onDeleteConversation = () => {
    const { conversationBlockId, currentXfdfHighlightId } = this.props;

    let conversationIdentifier = currentXfdfHighlightId;

    if (conversationBlockId) {
      conversationIdentifier = conversationBlockId;
    }

    let url = !this.props.inGuestViewer
      ? `/conversations/${conversationIdentifier}.json`
      : `delete/guest/conversation/${this.props.currentXfdfHighlightId}/${this.props.hashUrl}.json`;

    if (window.confirm("Are you sure you want to delete this Conversation?")) {
      httpClient.delete(url).then((response) => {
        if (currentXfdfHighlightId === conversationIdentifier) {
          const webViewerEl = document.getElementById("webViewer");
          const annotManager =
            window.WebViewer.getInstance(
              webViewerEl
            ).docViewer.getAnnotationManager();
          const annot = annotManager.getAnnotationById(conversationIdentifier);

          annotManager.deleteAnnotation(annot);
        }

        this.props.reloadConversations();
        this.props.closeConversation();
        notificationService.addNotification(
          "Conversation removed.",
          "Conversation has been deleted.",
          "danger"
        );
      });
    }
  };

  addTimestamp = (e) => {
    this.setState({ addTimestamp: e.target.checked });
  };

  onChange = (editorState) => {
    const text = editorState.getCurrentContent().getPlainText();
    this.setState({ editorState });
    if (this.state.conversation.id) {
      localStorage.setItem(
        `conversationID-${this.state.conversation.id}`,
        text
      );
    } else if (this.state.conversationLoaded) {
      localStorage.setItem(`conversationDocumentID-${this.props.docId}`, text);
    }
  };

  onAddAtMention = () => {
    const newContentState = Modifier.insertText(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      "@"
    );

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-fragment"
    );

    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        setTimeout(() => {
          this.focus();
        }, 200);
      }
    );
  };

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) =>
      list.constructor.name === "List" ? list.size : list.length;

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        !value || get(suggestion, "name").toLowerCase().indexOf(value) > -1
    );
    const length =
      size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 50;
    return filteredSuggestions.slice(0, length);
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: this.customSuggestionsFilter(value, this.state.mentions),
    });
  };

  entryComponent = (props) => {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    if (mention.guest === "true") {
      return <span style={{ display: "none" }}></span>;
    }

    let name = mention.name.split(" ");

    return (
      <div {...parentProps}>
        <div className={theme.mentionSuggestionsEntryAvatar}>
          {`${name[0].charAt(0)}${name[1].charAt(0)}`}
        </div>
        <div className="dashboardMentionSuggestionsTextBox">
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className="dashboardMentionSuggestionsTitle">
            {mention.title}
          </div>
        </div>
      </div>
    );
  };

  mentionsSuggestionsComponent = () => {
    return (
      <div
        className="hello"
        onSearchChange={this.onSearchChange}
        suggestions={this.state.suggestions}
        entryComponent={this.entryComponent}
      ></div>
    );
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    const actionsDropDown = ({ onClick }) => {
      return (
        <span onClick={onClick}>
          <img className={styles.moreIcon} src={moreSVG} />
        </span>
      );
    };

    return (
      <div
        id="conversationPanel"
        className={
          this.props.showMobileSidePanel
            ? styles.mobileCommentPanel
            : styles.commentPanel
        }
        ref={this.commentPanelRef}
      >
        <div className={styles.sidePanelHeader} id="sidepanelheader">
          {!this.props.is_video && (
            <span
              className={styles.backArrow}
              onClick={() => {
                this.props.closeConversation();
              }}
            >
              <img src={leftarrowSVG} />
            </span>
          )}
          <span className={styles.sidePanelHeadingTitle}>Conversation</span>
          {!this.props.approved &&
            this.state.conversation &&
            !this.state.conversation.block_identifier &&
            (this.props.viewingCurrentVersion || this.props.inGuestViewer) &&
            this.canDeleteConversation(this.state.conversation) && (
              <div className={styles.conversationActionsDropDownContainer}>
                <UikDropdown
                  DisplayComponent={actionsDropDown}
                  position="bottomRight"
                >
                  <UikDropdownItem
                    onClick={(e) => this.onDeleteConversation(e)}
                  >
                    Delete
                  </UikDropdownItem>
                </UikDropdown>
              </div>
            )}
          {!this.props.is_video &&
            !this.props.inGuestViewer &&
            this.state.conversation &&
            this.state.conversation.comments &&
            this.state.conversation.comments.length > 0 && (
              <div className={styles.checkmark}>
                <a
                  className={styles.resolve}
                  onClick={() => {
                    this.toggleConversationStatus();
                  }}
                >
                  {this.state.conversation.status === "resolved"
                    ? "Resolved"
                    : "Resolve"}
                </a>
                <Checkmark
                  disabled={this.props.readOnly}
                  status={this.state.conversation.status}
                  onClick={() => {
                    this.toggleConversationStatus();
                  }}
                />
              </div>
            )}
        </div>
        {this.state.suggestedClaims.length > 0 && this.renderFoundClaim()}
        <div
          className={styles.sidePanelOptions}
          id="sidePanelOptions"
          ref={this.sidePanelOptionsCallback}
        >
          <div>{this.renderComments()}</div>
        </div>
        {!this.props.readOnly && (
          <div className={styles.messageInput} id="messageInput">
            {this.props.is_video && (
              <div class={styles.addTimestamp}>
                <input type="checkbox" onChange={this.addTimestamp} />
                Add Timestamp (
                {moment()
                  .startOf("day")
                  .seconds(this.state.currentTimestamp)
                  .format("H:mm:ss")}
                )
              </div>
            )}
            <div className={styles.editor}>
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                placeholder="Type a message..."
                plugins={plugins}
                ref={this.setDomEditorRef}
              />
              <MentionSuggestions
                onOpenChange={true}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                entryComponent={this.entryComponent}
                onAddMention={this.addMention}
              />
            </div>
          </div>
        )}
        {!this.props.readOnly && (
          <div
            className={
              !this.props.inGuestViewer
                ? styles.messageInputSubmit
                : styles.messageInputSubmitGuest
            }
          >
            {!this.props.inGuestViewer && (
              <button
                disabled={this.state.sendButtonDisabled}
                onClick={this.onAddAtMention}
                className={styles.atButton}
              >
                @<p className={styles.buttonTextMention}>Mention</p>
              </button>
            )}
            <button
              disabled={this.state.sendButtonDisabled}
              onClick={this.addComment}
              className={styles.sendButton}
            >
              <div className={styles.sendContainer}>
                {this.state.showSpinner && (
                  <img className={styles.loading} src={spinnerSVG} />
                )}
                <img
                  className={`${
                    this.state.showSpinner
                      ? styles.hideSendButton
                      : styles.showSendButton
                  }`}
                  src={sendButtonSVG}
                />
                <p className={styles.buttonText}>Send</p>
              </div>
            </button>
          </div>
        )}
      </div>
    );
  }
}

CommentPanel.propTypes = {
  closeConversation: PropTypes.func,
  conversationBlockId: PropTypes.string,
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  legacyDocument: PropTypes.bool,
  currentXfdfHighlightId: PropTypes.string,
  currentXfdfHighlightString: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { searchResultLibrary: state.commentSearchResults };
};

const actionCreators = {
  resetSearchCommentResults,
};

export default connect(mapStateToProps, actionCreators)(CommentPanel);
