import React, { useEffect, useRef } from "react";
import Modal from "../../Common/Modal";

import closeBlack from "../../../images/icons/svg/close-black.svg";

import WebViewer from "@pdftron/webviewer";
import security from "../../../services/Security";
import { loadDocumentUrl } from "../../../lib/axios-client";
import styles from "./TemplatePreviewModal.module.scss";

export default (props) => {
  const { showTemplatePreviewModal, setShowTemplatePreviewModal, template } =
    props;

  const viewerRef = useRef(null);

  useEffect(() => {
    const loadDocument = async () => {
      try {
        let webViewerOptions = {
          path: "/vendor/pdf/lib",
          fullAPI: false,
          disabledElements: [
            "notesPanelResizeBar",
            "filterAnnotationButton",
            "notesOrderDropdown",
            "thumbDelete",
            "thumbRotateClockwise",
            "leftPanelResizeBar",
            "searchPanelResizeBar",
            "fullscreenButton",
            "printButton",
            "themeChangeButton",
            "deletePage",
            "insertPageBelow",
            "insertPageAbove",
            "pageInsertionHeader",
            "panToolButton",
            "selectToolButton",
            "toggleNotesButton",
            "menuButton",
            "annotationCommentButton",
            "annotationStyleEditButton",
            "linkButton",
            "annotationPopup",
            "outlinesPanelButton",
            "outlinesPanel",
            "contextMenuPopup",
            "notePopup",
            "thumbnailControl",
            "documentControl",
            "annotationContentOverlay",
            "layersPanelButton",
            "highlightToolButton",
            "highlightToolButton2",
            "highlightToolButton3",
            "highlightToolButton4",
            "underlineToolButton",
            "squigglyToolButton",
            "strikeoutToolButton",
            "ellipseToolButton",
            "lineToolButton",
            "arrowToolButton",
            "polylineToolButton",
            "polygonToolButton",
            "cloudToolButton",
            "calloutToolButton",
            "stampToolButton",
            "fileAttachmentToolButton",
            "cropToolButton",
            "freeHandToolButton",
            "freeHandToolButton2",
            "freeHandToolButton3",
            "freeHandToolButton4",
            "rectangleToolButton",
            "ribbons",
            "signaturePanelButton",
            "copyTextButton",
          ],
          css: "/vendor/pdf/lib/ui/ShareLinkViewerStyle.css",
          isAdminUser: true,
          licenseKey: `${process.env.REACT_APP_PDFTRON_LICENSE_KEY}`,
        };

        const instance = await WebViewer(webViewerOptions, viewerRef.current);

        const UIEvents = instance.UI.Events;

        instance.updateElement("viewControlsButton", {
          img: "/assets/svg/default-viewcontrols.svg",
        });

        instance.updateElement("leftPanelButton", {
          img: "/assets/svg/default-leftsidepanel.svg",
        });

        instance.updateElement("zoomOutButton", {
          img: "/assets/svg/default-minus.svg",
        });

        instance.updateElement("zoomInButton", {
          img: "/assets/svg/default-plus.svg",
        });

        instance.updateElement("zoomOverlay", {
          img: "/assets/svg/chevron.svg",
        });

        instance.updateElement("panToolButton", {
          img: "/assets/svg/default-pan.svg",
        });

        instance.updateElement("searchButton", {
          img: "/assets/svg/default-magnifying-glass.svg",
        });

        instance.disableTools([
          "TextSelectTool",
          "AnnotationComment",
          "AnnotationCreateTextSquiggly",
          "AnnotationCreateFileAttachment",
          "AnnotationCreateAreaMeasurement",
          "AnnotationCreateArrow",
          "AnnotationCreateCallout",
          "AnnotationCreateCountMeasurement",
          "CropPage",
          "AnnotationCreateDistanceMeasurement",
          "AnnotationCreateEllipse",
          "AnnotationCreateEllipseMeasurement",
          "AnnotationEraserTool",
          "AnnotationCreateFreeHand",
          "AnnotationCreateFreeHand2",
          "AnnotationCreateFreeHand3",
          "AnnotationCreateFreeHand4",
          "AnnotationCreateFreeText",
          "AnnotationCreateLine",
          "AnnotationCreatePerimeterMeasurement",
          "AnnotationCreatePolygon",
          "AnnotationCreatePolygonCloud",
          "AnnotationCreatePolyline",
          "AnnotationCreateRectangle",
          "AnnotationCreateRectangularAreaMeasurement",
          "AnnotationCreateRedaction",
          "AnnotationCreateRubberStamp",
          "AnnotationCreateStamp",
          "AnnotationCreateSticky",
          "AnnotationCreateTextStrikeout",
          "TextSelect",
          "AnnotationCreateTextUnderline",
          "AnnotationCreateTextHighlight2",
          "AnnotationCreateTextHighlight3",
          "AnnotationCreateTextHighlight4",
          "AnnotationCreateTextHighlight",
        ]);

        const token = security.getToken();

        instance.UI.loadDocument(
          `${loadDocumentUrl()}/document_templates/preview/${template.id}`,
          {
            customHeaders: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        console.log("error");
      }
    };
    loadDocument();
  }, []);

  return (
    <Modal
      id="modal"
      visible={showTemplatePreviewModal}
      type={"templatePreviewModal"}
    >
      <div className={styles.activityLogHeaderContainer}>
        <h1 className={styles.activityLogHeading}>{template.title}</h1>

        <img
          className={styles.closeButton}
          onClick={() => setShowTemplatePreviewModal(false)}
          src={closeBlack}
        />
      </div>
      <div className={styles.webViewerContainer} ref={viewerRef}></div>
    </Modal>
  );
};
