import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";

import styles from "./SettingsLeftMenu.module.scss";

import security from "../../../services/Security";
import Setup2FA from "../../2FA/Setup2FA";
import httpClient from "../../../lib/HttpClient";

import papercurveLogo from "../../../images/logos/papercurve-logo-colour.svg";
import accountSetting from "../../../services/AccountSetting";

class SettingsLeftMenuBuild extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole,
      },
    };
  }

  render() {
    const { t } = this.props;

    const users = window.location.pathname.match(/^\/users/) ? true : false;
    const libraries = window.location.pathname.match(/^\/libraries/)
      ? true
      : false;
    const tags = window.location.pathname.match(/^\/tags/) ? true : false;
    const activitylog = window.location.pathname.match(/^\/activitylog/)
      ? true
      : false;
    const personalinfo = window.location.pathname.match(/^\/personalinfo/)
      ? true
      : false;
    const setupsecurity = window.location.pathname.match(/^\/setupsecurity/)
      ? true
      : false;
    const notifications = window.location.pathname.match(/^\/notifications/)
      ? true
      : false;
    const language = window.location.pathname.match(/^\/language/)
      ? true
      : false;

    return (
      <Col md={2} className={styles.leftSideMenuContainer}>
        {this.state.currentUser.role !== "viewer" && (
          <div className={styles.leftSideMenu}>
            <div className={[styles.leftSideMenuItem, styles.header].join(" ")}>
              {t("settings_left_menu.workspace")}
            </div>
            <div
              className={`${users ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/users">{t("settings_left_menu.users")}</a>
            </div>
            {this.state.currentUser.role === "admin" && (
              <div
                className={`${libraries ? styles.selected : ""} ${
                  styles.leftSideMenuItem
                }`}
              >
                <a href="/libraries">{t("settings_left_menu.libraries")}</a>
              </div>
            )}
            {this.state.currentUser.role === "admin" && (
              <div
                className={`${activitylog ? styles.selected : ""} ${
                  styles.leftSideMenuItem
                }`}
              >
                <a href="/activitylog">
                  {t("settings_left_menu.activity_log")}
                </a>
              </div>
            )}
            <div
              className={`${tags ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/tags">{t("settings_left_menu.tags")}</a>
            </div>
          </div>
        )}

        <div>
          <div className={[styles.leftSideMenuItem, styles.header].join(" ")}>
            {t("settings_left_menu.my_account")}
          </div>

          <div
            className={`${personalinfo ? styles.selected : ""} ${
              styles.leftSideMenuItem
            }`}
          >
            <a href="/personalinfo">{t("settings_left_menu.personal_info")}</a>
          </div>
          <div
            className={`${setupsecurity ? styles.selected : ""} ${
              styles.leftSideMenuItem
            }`}
          >
            <a href="/setupsecurity">{t("settings_left_menu.security")}</a>
          </div>
          <div
            className={`${notifications ? styles.selected : ""} ${
              styles.leftSideMenuItem
            }`}
          >
            <a href="/notifications">{t("settings_left_menu.notifications")}</a>
          </div>
          {accountSetting.getSetting("multi_language") === "TRUE" && (
            <div
              className={`${language ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/language">{t("settings_left_menu.language")}</a>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

const SettingsLeftMenu = withTranslation()(SettingsLeftMenuBuild);

export default SettingsLeftMenu;
