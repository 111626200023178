import React, { useState, useEffect } from "react";

import moment from "moment";
import CountPill from "../../../Common/CountPill";
import Confidence from "../../../Common/Confidence";

import styles from "./ClaimCard.module.scss";

export default (props) => {
  const {
    claim,
    setSelectedClaim,
    paigeSuggestedClaim,
    searchActive,
    linkClaim,
    inCommentPanel,
    readOnly,
    searchTerm,
  } = props;

  const claimClicked = (claim) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(
      claim.xfdf_highlight_id
    );
    if (!paigeSuggestedClaim && !searchActive) {
      instance.annotManager.deselectAllAnnotations();
      instance.annotManager.selectAnnotation(annotation);
      instance.docViewer.setCurrentPage(claim.page.page_number);
      instance.annotManager.jumpToAnnotation(annotation);
      setSelectedClaim(claim);
    }
  };

  const getCaption = () => {
    if (searchTerm) {
      if (claim.linked) {
        return highlightSearchTerm(claim.claim.caption, searchTerm);
      } else {
        return highlightSearchTerm(claim.caption, searchTerm);
      }
    } else {
      if (claim.linked) {
        return claim.claim.caption;
      } else {
        return claim.caption;
      }
    }
  };

  const getLabel = () => {
    if (searchTerm) {
      if (claim.linked) {
        return highlightSearchTerm(claim.claim.label, searchTerm);
      } else {
        return highlightSearchTerm(claim.label, searchTerm);
      }
    } else {
      if (claim.linked) {
        return claim.claim.label;
      } else {
        return claim.label;
      }
    }
  };

  const highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? styles.highlight
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  return (
    <div
      onClick={() => claimClicked(claim)}
      className={inCommentPanel ? styles.claimNoHover : styles.claim}
    >
      <div className={styles.metadata}>
        <div className={styles.labelContainer}>{getLabel()}</div>
        {claim.linked && <div className={styles.linkageIndicator}>Linked</div>}
      </div>
      <div className={styles.claimCaption}>{getCaption()}</div>
      {!paigeSuggestedClaim && !searchActive ? (
        <div className={styles.bottomContainer}>
          <div className={styles.nameDate}>
            <div className={styles.name}>
              {claim.linked
                ? claim.claim_created_by_user_name
                : claim.created_by_user_name}
            </div>
            <div className={styles.dotSeparator}>&#9679;</div>
            <div className={styles.date}>
              <div className={styles.fullDateTimeContainer}>
                <div className={styles.fullDateTime}>
                  {moment(
                    claim.linked ? claim.claim.created_at : claim.created_at
                  ).format("MMM D")}
                </div>
                <div className={styles.bottomArrow} />
              </div>
            </div>
          </div>
          <CountPill
            count={claim.linked ? claim.claim_link_count : claim.link_count}
          />
        </div>
      ) : searchActive && !readOnly ? (
        <div className={styles.bottomContainer}>
          <div className={styles.linkReferenceConfidence}>
            <div
              className={styles.linkReference}
              onClick={() => linkClaim(claim)}
            >
              Suggest Product Claim
            </div>
          </div>
        </div>
      ) : (
        paigeSuggestedClaim &&
        !searchActive &&
        !readOnly && (
          <div className={styles.bottomContainer}>
            <div className={styles.linkReferenceConfidence}>
              {!inCommentPanel && (
                <div
                  className={styles.linkReference}
                  onClick={() => linkClaim(claim)}
                >
                  Suggest Product Claim
                </div>
              )}
              {inCommentPanel && (
                <div className={styles.date}>
                  <div className={styles.nameDate}>
                    <div className={styles.name}>
                      {claim.linked
                        ? claim.claim_created_by_user_name
                        : claim.created_by_user_name}
                    </div>
                    <div className={styles.dotSeparator}>&#9679;</div>
                    <div className={styles.date}>
                      <div className={styles.fullDateTimeContainer}>
                        <div className={styles.fullDateTime}>
                          {moment(
                            claim.linked
                              ? claim.claim.created_at
                              : claim.created_at
                          ).format("MMM D")}
                        </div>
                        <div className={styles.bottomArrow} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Confidence percentage={claim.confidence * 100} />
            </div>
          </div>
        )
      )}
    </div>
  );
};
