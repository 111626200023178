import React, { useState, useEffect } from "react";

import moment from "moment";
import Checkmark from "../Common/Checkmark";
import CountPill from "../Common/CountPill";
import { useSelector } from "react-redux";

import styles from "./CommentCard.module.scss";

export default (props) => {
  const {
    convo,
    showGeneralComments,
    showAnnotationComments,
    readOnly,
    currentUserId,
    toggleConversationStatus,
  } = props;

  const annotationConversationClicked = (key, conv) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(
      conv.xfdf_highlight_id
    );

    showAnnotationComments(parseInt(key), conv.xfdf_highlight_id, false);

    instance.annotManager.deselectAllAnnotations();
    instance.annotManager.selectAnnotation(annotation);
    instance.docViewer.setCurrentPage(convo.annotation_page.page_number - 1);
  };

  const mention = new RegExp("{{(.*?)}}", "gi");

  const search = useSelector((state) => {
    if (state.commentSearchResults) {
      return state.commentSearchResults.searchTerm;
    }
  });

  const formatHighlights = (comment) => {
    let parts = comment.body ? comment.body.split(mention) : [];

    if (parts.length === 1) {
      parts = comment.body
        ? comment.body.split(new RegExp(`(${search})`, "gi"))
        : [];
    }
    const names = comment.body.match(mention);

    return (
      <span>
        {" "}
        {parts.map((part, i) => {
          const found = names && names.find((name) => name === `{{${part}}}`);
          if (part.match(new RegExp(`(${search})`, "gi"))) {
            let subsections = part.split(new RegExp(`(${search})`, "gi"));
            return subsections.map((section) => {
              if (
                search &&
                section.trim().toLowerCase() === search.toLowerCase()
              ) {
                return <span className={styles.highlight}>{section}</span>;
              } else {
                const higlightType =
                  currentUserId === comment.user.id
                    ? styles.myCommentHighlight
                    : styles.othersCommentHighlight;
                return (
                  <span key={i} className={found ? higlightType : {}}>
                    {section}
                  </span>
                );
              }
            });
          }
          if (search && part.trim().toLowerCase() === search.toLowerCase()) {
            return (
              <span key={i} className={styles.highlight}>
                {part}
              </span>
            );
          } else {
            const higlightType =
              currentUserId === comment.user.id
                ? styles.myCommentHighlight
                : styles.othersCommentHighlight;
            return (
              <span key={i} className={found ? higlightType : {}}>
                {part}
              </span>
            );
          }
        })}{" "}
      </span>
    );
  };

  const conversationClicked = (convo) => {
    if (convo.conversation.block_identifier) {
      showGeneralComments();
    } else {
      annotationConversationClicked(
        convo.annotation_page.page_number - 1,
        convo.conversation
      );
    }
  };

  return (
    <div
      onClick={() => conversationClicked(convo)}
      className={styles.conversation}
    >
      <div className={styles.metadata}>
        <div className={styles.leftContainer}>
          {
            <div className={styles.nameDate}>
              {convo.user.guest === true && (
                <div className={styles.name}>{convo.user.email}</div>
              )}
              {convo.user.guest !== true && (
                <div className={styles.name}>
                  {convo.user.first_name} {convo.user.last_name}
                </div>
              )}
              <div className={styles.dotSeparator}>&#9679;</div>
              <div className={styles.date}>
                {moment(convo.commented_at).calendar(null, {
                  lastDay: "[Yesterday]",
                  lastWeek: "MMM D",
                  sameDay: "LT",
                  sameElse: "MMM D",
                })}
                <div className={styles.fullDateTimeContainer}>
                  <div className={styles.fullDateTime}>
                    {moment(convo.commented_at).format(
                      "MMMM D, YYYY [at] h:mma"
                    )}
                  </div>
                  <div className={styles.bottomArrow} />
                </div>
              </div>
            </div>
          }
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.checkmarkContainer}>
            {readOnly && (
              <div className={styles.toggleDisabledContainer}>
                <div className={styles.disabledText}>
                  {`${
                    convo.conversation.status === "resolved"
                      ? "Resolved"
                      : "Not Resolved"
                  }`}{" "}
                  <span className={`${styles.dotSeparator} ${styles.alignDot}`}>
                    &#9679;
                  </span>{" "}
                  Read only
                </div>
                <div className={styles.bottomArrow} />
              </div>
            )}
            <Checkmark
              disabled={readOnly}
              status={convo.conversation.status}
              onClick={() => toggleConversationStatus(convo.conversation)}
            />
          </div>
        </div>
      </div>
      <div className={styles.latestCommentText}>{formatHighlights(convo)}</div>
      <div className={styles.countContainer}>
        <CountPill count={convo.associated_comments} />
      </div>
    </div>
  );
};
