import React, { useState, useEffect } from "react";
import httpClient from "../../lib/HttpClient";

import moment from "moment";
import Checkmark from "../Common/Checkmark";
import CountPill from "../Common/CountPill";
import { useSelector } from "react-redux";

import styles from "./ThreadCard.module.scss";

export default (props) => {
  const {
    convo,
    firstCommentorsComment,
    showGeneralComments,
    showAnnotationComments,
    readOnly,
    reloadConversations,
    toggleStatus,
  } = props;

  const [conversationStatus, setConversationStatus] = useState(
    convo.conversation.status
  );

  const annotationConversationClicked = (key, conv) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(
      conv.xfdf_highlight_id
    );

    showAnnotationComments(parseInt(key), conv.xfdf_highlight_id, false);

    instance.annotManager.deselectAllAnnotations();
    instance.annotManager.selectAnnotation(annotation);
    instance.docViewer.setCurrentPage(convo.annotation_page.page_number - 1);
  };

  const searchTerm = useSelector((state) => {
    if (state.commentSearchResults) {
      return state.commentSearchResults.searchTerm;
    }
  });

  const highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? styles.highlight
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  const formatMentions = (text) => {
    // Getting rid of the mentions delimiter {{}}
    const mention = new RegExp("{{(.*?)}}", "gi");
    return text.split(mention).join("");
  };

  const conversationClicked = (convo) => {
    if (convo.conversation.block_identifier) {
      showGeneralComments();
    } else {
      annotationConversationClicked(
        convo.annotation_page.page_number - 1,
        convo.conversation
      );
    }
  };

  const toggleConversationStatus = (conversation) => {
    let status = "resolved";
    if (conversation.status === "resolved") {
      status = "unresolved";
    }
    if (conversation.status !== status) {
      setConversationStatus(status);
    }
    httpClient
      .put(
        `/conversations/${
          conversation.xfdf_highlight_id || conversation.block_identifier
        }.json`,
        {
          conversation: {
            status,
          },
        }
      )
      .then((res) => {
        reloadConversations();
        toggleStatus();
      });
  };

  return (
    <div className={styles.threadContainer}>
      {firstCommentorsComment && (
        <div
          onClick={() => conversationClicked(firstCommentorsComment)}
          className={styles.conversation}
        >
          <div className={styles.firstCommentorsCommentContainer}>
            <div className={styles.metadata}>
              <div className={styles.leftContainer}>
                {
                  <div className={styles.nameDate}>
                    <div className={styles.name}>
                      {firstCommentorsComment.user.first_name}{" "}
                      {firstCommentorsComment.user.last_name}
                    </div>
                    <div className={styles.dotSeparator}>&#9679;</div>
                    <div className={styles.date}>
                      {moment(firstCommentorsComment.commented_at).calendar(
                        null,
                        {
                          lastDay: "[Yesterday]",
                          lastWeek: "MMM D",
                          sameDay: "LT",
                          sameElse: "MMM D",
                        }
                      )}
                      <div className={styles.fullDateTimeContainer}>
                        <div className={styles.fullDateTime}>
                          {moment(firstCommentorsComment.commented_at).format(
                            "MMMM D, YYYY [at] h:mma"
                          )}
                        </div>
                        <div className={styles.bottomArrow} />
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.checkmarkContainer}>
                  {readOnly && (
                    <div className={styles.toggleDisabledContainer}>
                      <div className={styles.disabledText}>
                        {`${
                          conversationStatus === "resolved"
                            ? "Resolved"
                            : "Not Resolved"
                        }`}{" "}
                        <span
                          className={`${styles.dotSeparator} ${styles.alignDot}`}
                        >
                          &#9679;
                        </span>{" "}
                        Read only
                      </div>
                      <div className={styles.bottomArrow} />
                    </div>
                  )}
                  <Checkmark
                    disabled={readOnly}
                    status={conversationStatus}
                    onClick={() =>
                      toggleConversationStatus(
                        firstCommentorsComment.conversation
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.latestCommentText}>
              {searchTerm
                ? highlightSearchTerm(
                    formatMentions(firstCommentorsComment.body),
                    searchTerm
                  )
                : formatMentions(firstCommentorsComment.body)}
            </div>
            <div className={styles.countContainer}>
              <CountPill count={firstCommentorsComment.associated_comments} />
            </div>
          </div>
        </div>
      )}

      <div
        onClick={() => conversationClicked(convo)}
        className={styles.conversation}
      >
        <div className={styles.currentUserCommentContainer}>
          <div className={styles.currentUserComment}>
            <div className={styles.metadata}>
              <div className={styles.leftContainer}>
                {
                  <div className={styles.nameDate}>
                    <div className={styles.name}>
                      {convo.user.first_name} {convo.user.last_name}
                    </div>
                    <div className={styles.dotSeparator}>&#9679;</div>
                    <div className={styles.date}>
                      {moment(convo.commented_at).calendar(null, {
                        lastDay: "[Yesterday]",
                        lastWeek: "MMM D",
                        sameDay: "LT",
                        sameElse: "MMM D",
                      })}
                      <div className={styles.fullDateTimeContainer}>
                        <div className={styles.fullDateTime}>
                          {moment(convo.commented_at).format(
                            "MMMM D, YYYY [at] h:mma"
                          )}
                        </div>
                        <div className={styles.bottomArrow} />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className={styles.latestCommentText}>
              {searchTerm
                ? highlightSearchTerm(formatMentions(convo.body), searchTerm)
                : formatMentions(convo.body)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
