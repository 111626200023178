import React, { useState, useEffect } from "react";
import httpClient from "../../lib/HttpClient";
import moment from "moment";
import RightArrowSVG from "../../images/icons/svg/Right-Arrow-Dark.svg";
import pindrop from "../../images/icons/svg/pindrop-comments.svg";
import rectangle from "../../images/icons/svg/rectangle-comments.svg";
import arrow from "../../images/icons/svg/arrow-comments.svg";
import strikethrough from "../../images/icons/svg/strikethrough-comments.svg";
import highlight from "../../images/icons/svg/highlight-comments.svg";
import polygon from "../../images/icons/svg/polygon-comments.svg";
import Checkmark from "../Common/Checkmark";
import CountPill from "../Common/CountPill";
import CommentSVG from "../../images/icons/svg/comment.svg";

import styles from "./ConversationCard.module.scss";

export default (props) => {
  const {
    convo,
    showGeneralComments,
    showAnnotationComments,
    readOnly,
    reloadConversations,
    toggleStatus,
    formatMentions,
    inGuestViewer,
  } = props;

  const annotationConversationClicked = (key, conv) => {
    const webViewerEl = document.getElementById("webViewer");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(
      conv.xfdf_highlight_id
    );

    showAnnotationComments(parseInt(key), conv.xfdf_highlight_id, false);

    instance.annotManager.deselectAllAnnotations();
    instance.annotManager.selectAnnotation(annotation);
    instance.docViewer.setCurrentPage(conv.page.page_number);
    instance.annotManager.jumpToAnnotation(annotation);
  };

  const conversationClicked = (convo) => {
    if (convo.block_identifier) {
      showGeneralComments();
    } else {
      annotationConversationClicked(convo.page.page_number - 1, convo);
    }
  };

  const toggleConversationStatus = (conversation) => {
    let status = "resolved";
    if (conversation.status === "resolved") {
      status = "unresolved";
    }

    let url = !props.inGuestViewer
      ? `/conversations/${
          conversation.xfdf_highlight_id || conversation.block_identifier
        }.json`
      : `/conversations/${
          conversation.xfdf_highlight_id || conversation.block_identifier
        }/guest.json`;

    httpClient
      .put(url, {
        hash_url: props.hashUrl,
        conversation: {
          status,
        },
      })
      .then((res) => {
        reloadConversations();
        !props.inGuestViewer && toggleStatus();
      });
  };

  const renderAnnotationTypeIndicator = (convo) => {
    if (convo.conversation_type === "highlight") {
      return <img className={styles.typeIndicator} src={highlight} />;
    } else if (convo.conversation_type === "strikethrough") {
      return <img className={styles.typeIndicator} src={strikethrough} />;
    } else if (convo.conversation_type === "rectangle") {
      return <img className={styles.typeIndicator} src={rectangle} />;
    } else if (convo.conversation_type === "arrow") {
      return <img className={styles.typeIndicator} src={arrow} />;
    } else if (convo.conversation_type === "pin") {
      return <img className={styles.typeIndicator} src={pindrop} />;
    } else if (convo.conversation_type === "polygon") {
      return <img className={styles.typeIndicator} src={polygon} />;
    }
  };

  return (
    <div
      onClick={() => conversationClicked(convo)}
      className={styles.conversation}
    >
      <div className={styles.metadata}>
        <div className={styles.leftContainer}>
          {convo.comments.length === 0 &&
            convo.conversation_type !== "strikethrough" && (
              <img className={styles.commentSVG} src={CommentSVG} />
            )}
          {convo.comments.length === 0 &&
            convo.conversation_type === "strikethrough" && (
              <div className={styles.nameDate}>
                {renderAnnotationTypeIndicator(convo)}
                <div className={styles.name}>
                  {convo.started_by_user.guest && (
                    <span>{convo.started_by_user.email}</span>
                  )}
                  {!convo.started_by_user.guest && (
                    <span>
                      {convo.started_by_user.first_name}{" "}
                      {convo.started_by_user.last_name}
                    </span>
                  )}
                </div>
                <div className={styles.dotSeparator}>&#9679;</div>
                <div className={styles.date}>
                  {moment(convo.created_at).calendar(null, {
                    lastDay: "[Yesterday]",
                    lastWeek: "MMM D",
                    sameDay: "LT",
                    sameElse: "MMM D",
                  })}
                  <div className={styles.fullDateTimeContainer}>
                    <div className={styles.fullDateTime}>
                      {moment(convo.created_at).format(
                        "MMMM D, YYYY [at] h:mma"
                      )}
                    </div>
                    <div className={styles.bottomArrow} />
                  </div>
                </div>
              </div>
            )}
          {convo.comments.length > 0 && (
            <div className={styles.nameDate}>
              {renderAnnotationTypeIndicator(convo)}
              <div className={styles.name}>
                {convo.comments[0].user.guest && (
                  <span>{convo.comments[0].user.email}</span>
                )}
                {!convo.comments[0].user.guest && (
                  <span>
                    {convo.comments[0].user.first_name}{" "}
                    {convo.comments[0].user.last_name}
                  </span>
                )}
              </div>
              <div className={styles.dotSeparator}>&#9679;</div>
              <div className={styles.date}>
                {moment(
                  convo.comments[convo.comments.length - 1].commented_at
                ).calendar(null, {
                  lastDay: "[Yesterday]",
                  lastWeek: "MMM D",
                  sameDay: "LT",
                  sameElse: "MMM D",
                })}
                <div className={styles.fullDateTimeContainer}>
                  <div className={styles.fullDateTime}>
                    {moment(
                      convo.comments[convo.comments.length - 1].commented_at
                    ).format("MMMM D, YYYY [at] h:mma")}
                  </div>
                  <div className={styles.bottomArrow} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.rightContainer}>
          {convo.comments.length === 0 &&
          convo.conversation_type !== "strikethrough" ? (
            <div className={styles.noCommentContainer}>
              <span>Add a comment</span>
              <div className={styles.arrowRight}>
                <img src={RightArrowSVG} />
              </div>
            </div>
          ) : (
            <div className={styles.checkmarkContainer}>
              {readOnly && (
                <div className={styles.toggleDisabledContainer}>
                  <div className={styles.disabledText}>
                    {`${
                      convo.status === "resolved" ? "Resolved" : "Not Resolved"
                    }`}{" "}
                    <span
                      className={`${styles.dotSeparator} ${styles.alignDot}`}
                    >
                      &#9679;
                    </span>{" "}
                    Read only
                  </div>
                  <div className={styles.bottomArrow} />
                </div>
              )}
              {!inGuestViewer && (
                <Checkmark
                  disabled={readOnly}
                  status={convo.status}
                  onClick={() => toggleConversationStatus(convo)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.latestCommentText}>
        {convo.comments.length > 0 && formatMentions(convo.comments[0].body)}
      </div>
      {convo.comments.length > 0 && (
        <div className={styles.countContainer}>
          <CountPill count={convo.comments.length} />
        </div>
      )}
    </div>
  );
};
